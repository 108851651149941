.optional-feature-list-item-name {
  text-align: right;
}
.optional-feature-new-list-item-name {
  text-align: center;
  width: 100px;
}
.optional-features-cards-container {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  row-gap: 27px;
  justify-items: center;
}
.optional-feature-card {
  height: 120px;
  aspect-ratio: 1/1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  border-radius: 18px;
  border: 2px solid rgba(209, 209, 209, 0.9);
}
.optional-feature-card-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 28px;
  aspect-ratio: 1/1;
}
.optional-feature-card-icon svg {
  max-width: 100%;
  max-height: 100%;
}
.optional-feature-card .optional-feature-card-icon {
  height: 40px;
}
.optional-feature-name {
  font-size: 14px;
  font-weight: 400;
  text-align: center;
}
.optional-features-list-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  row-gap: 27px;
  justify-items: center;
  padding: 0 60px;
}
.optional-features-list-syc-container {
  row-gap: 10px;
  padding: 0;
}
.optional-feature-new-list-item {
  width: 127.51px;
  height: 127.51px;
  border-radius: 17px;
  border: 0.94px;
  padding: 22.64px;
  gap: 8.5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}
.optional-feature-list-item {
  width: 220px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 7px 12px;
  border-radius: 12px;
  border: 2px solid rgba(209, 209, 209, 0.9);
  cursor: pointer;
  max-height: 46px;
}
.optional-features-new-list-syc-item {
  width: 127.51px;
  border-radius: 17px;
  border: 0.94px solid var(--stroke, #d1d1d1);
}
.optional-features-list-syc-item {
  width: 230px;
  border-radius: 5px;
}
.optional-feature-list-item.btn:hover {
  border: 2px solid;
}
.optional-feature-new-list-item-active {
  background: var(--Color, #e2e8f0);
}
.optional-feature-list-item-active {
  border: 2px solid #00d5bb;
}
.comment-header-heading {
  font-size: 18px;
  font-weight: 800;
}
.comment-header {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 10px;
}
.comment-box {
  padding: 0 20px 20px;
  margin: 0 0 0 auto;
}
.slider-labels:after {
  display: block;
  content: '';
  clear: both;
}
.slider-labels .caption {
  float: left;
  font-size: 13px;
  padding: 7px 12px;
  border-radius: 25px;
  background-color: #ebebeb;
  min-width: 80px;
  text-align: center;
  box-shadow: 0 2px 0 #cbcbcb;
  font-weight: 600;
  position: relative;
}
.slider-labels .caption.right {
  float: right;
}
.slider-labels .caption.addplus:after {
  content: ' +';
}
.switch-buttons li {
  vertical-align: top;
}
.switch-buttons.search-attributes {
  max-width: 770px;
}
.switch-buttons li {
  display: inline-block;
  margin: 0 0 5px;
  min-width: 180px;
}
@media (max-width: 1399px) {
  .optional-features-list-container {
    padding: 0 40px;
  }
  .optional-features-list-syc-container {
    padding: 0;
    gap: 15px;
  }
}
@media (max-width: 1199px) {
  .optional-features-cards-container {
    grid-template-columns: repeat(4, 1fr);
  }
  .optional-features-list-container {
    grid-template-columns: repeat(3, 1fr);
  }
  .optional-features-list-syc-container {
    grid-template-columns: repeat(3, 1fr);
    padding: 0;
    gap: 15px;
  }
}
@media (max-width: 992px) {
  .optional-features-list-syc-container {
    grid-template-columns: repeat(2, 1fr);
    padding: 0;
    gap: 15px;
  }
}
@media (max-width: 849px) {
  .optional-features-list-container {
    padding: 0 60px;
    grid-template-columns: repeat(2, 1fr);
  }
  .optional-features-list-syc-container {
    grid-template-columns: repeat(2, 1fr);
    padding: 0 25px;
  }
}
@media (max-width: 767px) {
  .switch-buttons li {
    margin: 0 10px 15px 0;
    min-width: 160px;
  }
}
@media (max-width: 700px) {
  .optional-features-list-container {
    grid-template-columns: repeat(1, 1fr);
  }
  .optional-features-list-syc-container {
    grid-template-columns: repeat(1, 1fr);
    padding: unset;
  }
  .optional-feature-list-item {
    width: 230px;
  }
}
@media (max-width: 659px) {
  .optional-features-list-container {
    padding: 0 20px;
  }
}
@media (max-width: 650px) {
  .optional-feature-card {
    height: 95px;
    padding: 6px 10px 15px;
  }
  .optional-feature-card-icon svg {
    height: 22px;
  }
}
@media (max-width: 575px) {
  .optional-features-list-container {
    padding: unset;
  }
}

@media (max-width: 529px) {
  .optional-features-cards-container {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (max-width: 480px) {
  .optional-feature-card {
    height: 90px;
  }
  .optional-feature-card-icon svg {
    height: 20px;
  }
}
@media (max-width: 465px) {
  .optional-features-list-container {
    grid-template-columns: repeat(1, 1fr);
  }
  .optional-feature-list-item {
    width: 230px;
  }
}
@media (max-width: 399px) {
  .optional-features-cards-container {
    grid-template-columns: repeat(2, 1fr);
  }
}
