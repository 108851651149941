.login-form .qr-code {
  display: none !important;
}
.login-button img {
  border-radius: 50px !important;
}
@media only screen and (min-width: 821px) {
  .slider-section.get-deals {
    min-width: 100% !important;
  }
}
@media only screen and (max-width: 991px) {
  .deals-left .deals-advantages,
  .deals-left .dealsimg2 {
    width: 100% !important;
  }
  .slider-nav-thumbnails2 .item {
    height: 12rem;
    width: 12rem;
  }
}
@media only screen and (max-width: 480px) {
  .btn-list,
  .btn-shop,
  .deals-left .deals-nav {
    display: none !important;
  }
  .accounts-aside,
  .callus-section .call-img,
  .deals-advantages,
  .deals-left .dealsimg2,
  .deals-right,
  .deals-right .box,
  .deals-right .frame,
  .search-details .deals-left {
    width: 100% !important;
  }
  .deals-advantages .deal-title,
  .english .pl-3,
  .login-button {
    font-size: 16px !important;
  }
  .nav-item .active {
    background-color: #ebebeb !important;
    color: #000 !important;
  }
  .navbar-light .navbar-nav > .nav-item.active > .nav-link,
  .navbar-light .navbar-nav > .nav-item.focus > .nav-link,
  .navbar-light .navbar-nav > .nav-item.show > .nav-link {
    background-color: #00d6bc00 !important;
    color: #000 !important;
  }
  #scrollbutton {
    margin-left: 15% !important;
  }
  .deals-advantages .small-logos {
    right: 0 !important;
  }
  .deals-advantages .deal-title,
  .deals-advantages h2 {
    text-overflow: initial !important;
  }
  .deals-left .deals-nav {
    padding-top: 40px !important;
    z-index: 300 !important;
  }
  .deals-right .frame .btn-secondary {
    max-width: 100px;
    border-radius: 4px !important;
  }
  .deals-right .frame strong {
    padding-top: 10px !important;
    color: #00d6bc !important;
    font-size: 18px !important;
    width: 50% !important;
    text-align: left !important;
    text-overflow: clip !important;
  }
  .login-signup .login-img {
    height: 135px !important;
  }
  .login-button {
    text-overflow: clip !important;
    max-width: 200px !important;
  }
  .deals-right {
    padding: 5px 0 15px !important;
  }
  .deals-right .frame {
    flex-direction: row !important;
    height: auto !important;
    padding-top: 5px !important;
  }
  .deals-left .img-box {
    max-width: 400px !important;
    height: 14.5rem !important;
  }
  .deals-right .cars-info-list {
    font-size: 12px !important;
  }
  .deals-advantages h2 {
    font-size: 18px !important;
  }
  .deals-advantages .small-logos img {
    max-height: 40px !important;
    padding-right: 10px !important;
  }
  .car-info-colunm .price-holder .btn {
    font-weight: 400 !important;
    font-size: 17px !important;
    width: auto !important;
    padding: 17px 14px !important;
  }
  .search-details .deals-right .frame {
    justify-content: space-between !important;
  }

  .callus-section .call-img {
    padding: 10px 0 !important;
  }
  .search-details .deals-left {
    padding: 0 !important;
  }
  .search-details .deals-advantages {
    max-width: 4444px !important;
    padding-left: 10px !important;
  }
  .deals-right .box,
  .deals-right .frame {
    padding-right: 10px !important;
    padding-left: 10px !important;
  }
  #used_cars_for_sale_header {
    margin-bottom: 30px !important;
  }
  .accounts-aside {
    border-right: 1px solid #ebe9e9 !important;
  }
  .lang-au .slider-holder.used-detail .slide-content,
  .slider-holder.used-detail .slide-content {
    height: 14rem !important;
  }
  .slider-nav-thumbnails2 .item {
    height: 5rem !important;
    width: 5rem !important;
  }
  .lang-au .navbar-nav .nav-item {
    text-align: start !important;
  }
  .lang-au #scrollbutton {
    margin-right: 15% !important;
  }
  .lang-au .deals-advantages .small-logos {
    left: 0 !important;
    right: unset !important;
  }
  .lang-au .deals-right {
    width: 100% !important;
    padding: 5px 10px 15px !important;
  }
  .lang-au .container.usedcars header.title-header {
    padding-right: 20px !important;
    padding-left: 0 !important;
  }
  .lang-au .search-details .deals-right .box {
    width: 100% !important;
    margin-right: -20px !important;
    padding-right: 0 !important;
  }
  .lang-au .deals-right .frame strong {
    color: #00d6bc !important;
    font-size: 18px !important;
    width: 100% !important;
    text-align: right !important;
  }
  .lang-au span.qar {
    float: right !important;
    margin-left: 5px !important;
  }
  .lang-au .deals-right .frame .btn-secondary {
    max-width: 140px !important;
  }
  .lang-au .slider-nav-thumbnails2 .item {
    height: 4rem !important;
  }
}
