.ramadan-offer-card {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding-bottom: 10px;
  border-radius: 15px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
}

.ramadan-offer-card-carousel {
  display: flex;
  flex-direction: column;
  width: 94%;
  height: 100%;
  padding-bottom: 10px;
  border-radius: 15px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
}

.ramadan-offer-card-title-icon {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 5px;
}

@media screen and (max-width: 1400px) {
  .ramadan-carousel-home-image {
    height: 134px !important;
  }

  .page-list-ramadan-image {
    height: 144px !important;
  }
}

@media screen and (max-width: 1200px) {
  .ramadan-carousel-home-image {
    height: 110px !important;
  }

  .page-list-ramadan-image {
    height: 119px !important;
  }
}

@media screen and (max-width: 1024px) {
  .ramadan-carousel-home-image {
    height: 170px !important;
  }

  .page-list-ramadan-image {
    height: 183px !important;
  }
}

@media screen and (max-width: 992px) {
  .ramadan-carousel-home-image {
    height: 122px !important;
  }

  .page-list-ramadan-image {
    height: 133px !important;
  }
}

@media screen and (max-width: 768px) {
  .page-list-ramadan-image {
    height: 139px !important;
  }
}

@media screen and (max-width: 540px) {
  .ramadan-carousel-home-image {
    height: 174px !important;
  }

  .page-list-ramadan-image {
    height: 200px !important;
  }
}

@media screen and (max-width: 430px) {
  .ramadan-carousel-home-image {
    height: 137px !important;
  }

  .page-list-ramadan-image {
    height: 154px !important;
  }
}

@media screen and (max-width: 414px) {
  .ramadan-carousel-home-image {
    height: 131px !important;
  }

  .page-list-ramadan-image {
    height: 147px !important;
  }
}

@media screen and (max-width: 412px) {
  .ramadan-carousel-home-image {
    height: 130px !important;
  }

  .page-list-ramadan-image {
    height: 146px !important;
  }
}

@media screen and (max-width: 390px) {
  .ramadan-carousel-home-image {
    height: 122px !important;
  }

  .page-list-ramadan-image {
    height: 137px !important;
  }
}

@media screen and (max-width: 375px) {
  .ramadan-carousel-home-image {
    height: 116px !important;
  }

  .page-list-ramadan-image {
    height: 131px !important;
  }
}

@media screen and (max-width: 360px) {
  .ramadan-carousel-home-image {
    height: 110px !important;
  }

  .page-list-ramadan-image {
    height: 125px !important;
  }
}

@media screen and (max-width: 280px) {
  .ramadan-carousel-home-image {
    height: 94px !important;
  }

  .page-list-ramadan-image {
    height: 108px !important;
  }
}
