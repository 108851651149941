.title-header {
  background-color: #01d5bc;
  padding: 8px 240px 8px 20px;
  border-radius: 10px;
  position: relative;
  margin-bottom: 30px;
}
.title-header h1 {
  line-height: 1.5;
  color: #fff;
}
.title-header h1 {
  font-size: 25px;
  font-weight: 900;
}
.paging-holder {
  position: relative;
  background-color: #fff;
  border: 1px solid #959595;
  padding: 15px 65px 15px 15px;
  border-radius: 10px;
  min-height: 64px;
}
.paging-holder i {
  transform: rotate(-90deg);
  color: #fff;
  position: absolute;
  left: -30px;
  top: 20px;
  font-size: 18px;
}
.paging-holder .counts {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  color: #818181;
  font-size: 14px;
}
.dropdown-toggle::after .dropdown-toggle::after {
  display: none;
}
.fitlers-drop .dropdown-toggle::after {
  display: none;
}
.fitlers-drop.dropdown {
  margin-right: 10px;
}
.fitlers-drop .dropdown-toggle {
  outline: 0;
  padding: 0;
  min-width: 0;
}
.fitlers-drop.dropdown .dropdown-menu {
  border-radius: 0;
  width: 185px;
  padding-inline: 10px;
  box-sizing: border-box;
}
.fitlers-drop.dropdown .dropdown-menu .dropdown-item {
  padding: 0.7rem 1rem 0.7rem 1.5rem;
  color: #848484;
  font-size: 13px;
  width: 100%;
  border-top: 1px solid #d7d7d7;
  text-transform: capitalize;
}
.fitlers-drop.dropdown .dropdown-menu .dropdown-item:last-child {
  border-bottom: 1px solid #d7d7d7;
}
.fitlers-drop.dropdown .dropdown-item.active,
.fitlers-drop.dropdown .dropdown-item:active,
.fitlers-drop.dropdown .dropdown-item:focus,
.fitlers-drop.dropdown .dropdown-item:hover {
  color: #1d2327;
  text-decoration: none;
  outline: 0;
  box-shadow: none;
  background-color: #ebebeb;
}
.drop-page-holder {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  z-index: 99;
}
.pagination-wrapper > nav > ul > li:first-child > button > svg {
  display: none !important;
}
.pagination-wrapper > nav > ul > li:last-child > button > svg {
  display: none !important;
}
.pagination-wrapper > nav > ul > li:first-child > button:disabled {
  background: #d9dad9 url('../../common/images/leftArrow.webp') no-repeat 50%;
  border-color: #d9dad9;
}
.pagination-wrapper > nav > ul > li:first-child > button {
  background: #01d5bc url('../../common/images/leftArrow.webp') no-repeat 50%;
  border-color: #01d5bc;
  opacity: 1;
}
.pagination-wrapper > nav > ul > li:last-child > button:disabled {
  background: #d9dad9 url('../../common//images/rightArrow.webp') no-repeat 50%;
  border-color: #d9dad9;
}
.pagination-wrapper > nav > ul > li:last-child > button {
  background: #01d5bc url('../../common//images/rightArrow.webp') no-repeat 50%;
  border-color: #01d5bc;
  opacity: 1;
}
.pagination-wrapper > nav > ul > li > button,
.pagination-wrapper > nav > ul > li > button:focus,
.pagination-wrapper > nav > ul > li > button:hover,
.pagination-wrapper > nav > ul > li > button:active {
  outline: none !important;
}
.pagination-wrapper > nav > ul > li > button.Mui-selected,
.pagination-wrapper > nav > ul > li > button.Mui-selected:focus,
.pagination-wrapper > nav > ul > li > button.Mui-selected:hover,
.pagination-wrapper > nav > ul > li > button.Mui-selected:active {
  background-color: #01d5bc !important;
  border-color: #01d5bc !important;
}
[dir='rtl'].pagination-wrapper > nav > ul > li:first-child,
[dir='rtl'].pagination-wrapper > nav > ul > li:last-child {
  transform: scaleX(-1);
}
@media (max-width: 991px) {
  .paging-holder {
    padding-left: 65px;
    padding-right: 15px;
    display: none;
  }
}
@media (max-width: 767px) {
  .title-header .paging-holder {
    display: none;
  }
  .title-header h1 {
    font-size: 20px;
  }
  .title-header {
    margin-top: 20px;
  }
  .title-header .paging-holder {
    padding: 11px 60px 11px 15px;
  }
  .lang-au .dropdown-menu.dropdown-menu-right.show {
    transform: translate3d(0, 28px, 0) !important;
  }
  .dropdown-menu.dropdown-menu-right.show {
    transform: translate3d(-156px, 28px, 0) !important;
  }
}
@media (max-width: 575px) {
  .title-header {
    padding: 8px 15px;
    margin-bottom: 20px;
    margin-top: 0;
  }
  .title-header h1 {
    margin-bottom: 10px;
  }
  .title-header .paging-holder {
    padding: 11px 15px;
    position: relative;
    right: auto;
    top: auto;
    transform: translate(0);
    margin-left: 30px;
  }
}
@media (max-width: 479px) {
  .title-header .paging-holder {
    display: none !important;
    padding: 11px 40px 11px 20px !important;
    position: absolute !important;
    right: 0 !important;
    top: 46px !important;
  }
  .lang-au .title-header .paging-holder {
    padding: 15px 10px 11px 45px !important;
    position: absolute !important;
    right: auto !important;
    left: 0 !important;
    top: 43px !important;
    margin-left: 0 !important;
  }
}
