.user-chat-form .btn-box {
  top: 50%;
  transform: translateY(-50%);
}
.user-chat-form {
  border-top: 1px solid #dedede;
  padding: 20px 20px 20px 100px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  z-index: 9;
  background-color: #fff;
}
.user-chat-form .btn-box {
  position: absolute;
  left: 30px;
  display: flex;
}
.user-chat-form .chat-buttons {
  display: inline-block;
  vertical-align: middle;
  max-width: 26px;
  max-height: 29px;
  margin-inline: 10px;
}
.user-chat-form .chat-buttons img {
  max-width: 100%;
  max-height: 29px;
}
.user-chat-form .chat-form-row {
  width: 100%;
  position: relative;
  box-sizing: border-box;
  padding-right: 85px;
}
.user-chat-form .form-btn {
  background-color: transparent;
  position: absolute;
  right: 32px;
  top: 35%;
  transform: translateY(-50%);
  width: 31px;
  height: 24px;
  background-size: contain;
  font-size: 0;
  border: 0;
  outline: 0;
}
.user-chat-form .form-control {
  border-radius: 5px;
}
.user-chat-section {
  width: 64%;
  padding: 10px 13px;
  box-sizing: border-box;
  position: relative;
  scroll-behavior: auto;
  height: calc(100vh);
}
.user-chat-holder {
  position: relative;
  padding-top: 20px;
}
.user-chat-holder.customscroll {
  height: calc(100vh - 155px);
  overflow-x: hidden;
  overflow-y: auto;
}
.user-chat-row {
  width: 100%;
  padding: 15px 25px;
}
.user-chat-row:after {
  display: block;
  content: '';
  clear: both;
}
.user-chat-box {
  position: relative;
  padding: 3% 4%;
  border-radius: 20px;
  background-color: #00d6bc;
  color: #fff;
  font-size: 20px;
  line-height: 1.3;
  float: right;
  max-width: 50%;
  z-index: 2;
}
.user-chat-box:after {
  position: absolute;
  right: -11px;
  bottom: 0;
  content: '';
  width: 18px;
  height: 16px;
  background: url(/public/images/chat-after-img1.png) no-repeat;
  z-index: -1;
}
.admin-chat-row .user-chat-box {
  background-color: #ebe9e9;
  color: #3d3d3d;
  float: left;
}
.admin-chat-row .user-chat-box:after {
  right: auto;
  left: -11px;
  background-image: url(/public/images/chat-after-img2.png);
}
.chat-listing-info {
  position: sticky;
  padding-block: 5px;
  z-index: 100;
  top: 0;
  padding-inline: 15px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  background-color: #ebe9e9;
}
.chat-listing-info .text-box {
  width: 60%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.chat-listing-info .img-holder {
  width: 64px;
  height: 64px;
  position: sticky;
  border-radius: 10px;
  overflow: hidden;
  margin-right: 15px;
}
.chat-listing-info .box {
  font-size: 15px;
  line-height: 1.4;
  font-weight: 600;
}
.chat-listing-info h2 {
  color: #3d3d3d;
  font-size: 15px;
  font-weight: 600;
}
.chat-listing-info p {
  color: #6a6a6a;
}
.chat-listing-info p sub {
  font-size: 13px;
}
@media screen and (max-width: 991px) {
  .user-chat-form {
    padding: 20px;
  }
}
@media (max-width: 595px) {
  .chat-listing-info .text-box {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 15px;
  }
  .user-chat-holder.customscroll {
    height: calc(100vh - 155px);
    overflow-x: hidden;
    overflow-y: auto;
    padding-bottom: 30px !important;
  }
  .user-chat-form {
    border-top: 1px solid #dedede;
    position: sticky;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    z-index: 9;
    background-color: #fff;
  }
  .user-chat-section {
    width: 100%;
    padding: 10px 13px;
    box-sizing: border-box;
    position: relative;
    scroll-behavior: auto;
    height: calc(100vh);
  }
}
.lang-au .admin-chat-row .user-chat-box,
.lang-au .user-chat-box {
  float: right;
}
.lang-au .chat-listing-info .text-box {
  text-align: right;
}
