.accounts-content .accounts-details.hide-content dd .form-control {
  display: block;
}
.accounts-content .accounts-details.hide-content dd p {
  display: none;
}
.accounts-content {
  width: 75%;
  position: relative;
}
.accounts-content.chat-page {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
}
.float-left {
  float: left;
}
.notification-icon img {
  max-height: 280px;
}
.notification-icon {
  max-width: 245px;
  margin: 0 auto 30px;
}
.accounts-container {
  max-width: 1625px;
  margin: 30px auto;
  position: relative;
  padding-inline: 15px;
}
.accounts-container:after,
.accounts-frame:after {
  display: block;
  clear: both;
  content: '';
}
.accounts-frame {
  border: 1px solid #e7e7e7;
  border-radius: 10px;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  background-color: #fff;
  overflow: hidden;
}
.accounts-frame .search-details .deals-right .frame {
  padding-top: 12px;
}
.accounts-frame .search-details {
  padding-inline: 20px;
}
.empaty-notification {
  padding: 150px 20px 30px;
  text-align: center;
}
.empaty-notification h2 {
  color: #3d3d3d;
  font-weight: 800;
  font-size: 30px;
  line-height: 1.4;
  margin-bottom: 30px;
}
.empaty-notification .btn-secondary {
  border-radius: 5px;
  min-width: 250px;
}
.accounts-edit {
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  right: 15px;
  color: #3d3d3d;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.accounts-edit i {
  font-size: 24px;
  margin-right: 10px;
}
.lang-au .accounts-edit {
  right: auto;
  left: 15px;
}
.lang-au .accounts-edit i {
  margin-right: 0;
  margin-left: 10px;
}
.accounts-header {
  background-color: #ebebeb;
  color: #6a6a6a;
  border-bottom: 3px solid #00d6bc;
  padding: 12px 15px;
  text-transform: capitalize;
  position: relative;
}
.accounts-header.listing .btn {
  top: 50%;
  transform: translateY(-50%);
}
.accounts-header.details {
  color: #3d3d3d;
  background-color: #ebe9e9;
  border-bottom: 0;
  padding-block: 15px;
}
.accounts-header h2 {
  font-weight: 700;
  font-size: 18px;
  line-height: 1.4;
}
.accounts-header.listing {
  background-color: transparent;
  border-bottom: 0;
  padding-block: 20px;
  margin-bottom: 20px;
}
.accounts-header.listing h2 {
  font-weight: 900;
  color: #000;
}
.accounts-header.listing .btn {
  position: absolute;
  right: 20px;
  min-width: inherit;
}
@media (max-width: 1199px) {
  .accounts-header h2 {
    font-size: 17px;
  }
}
@media screen and (max-width: 991px) {
  .accounts-content {
    width: 100%;
    position: relative;
    padding-top: 50px;
  }
}
@media (max-width: 480px) {
  .accounts-content {
    width: 100% !important;
  }
}
.lang-au .accounts-header h2 {
  text-align: right;
}
.lang-au .accounts-header.listing .btn {
  right: auto;
  left: 20px;
}
