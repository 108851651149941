.default-image-banner {
  overflow: hidden;
  width: 100%;
  margin: 45px auto 0;
  max-width: 980px;
}
.default-image-banner img {
  width: 100%;
  max-height: 8rem;
  object-fit: cover;
}
.login-banner {
  position: relative;
  width: 64%;
  object-fit: cover;
}
.login-banner img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.a3-image-banner {
  max-width: 90%;
}
.C2-margin {
  margin-block: 20px;
}
.C3-margin {
  margin-block: 20px;
}
@media (max-width: 990px) {
  .login-banner img {
    object-fit: fill;
  }
  .login-banner {
    margin-top: 20px;
  }
  .signup-banner {
    margin-bottom: 20px;
  }
}
