.country-selector-modal {
  display: flex;
  width: 604px;
  padding: 48px 48px 32px 48px;
  flex-direction: column;
  align-items: flex-start;
  gap: 48px;
  flex-shrink: 0;
  border-radius: 5px;
  background: #fff;
  margin: 200px auto;
}
.country-selector-modal-content-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  align-self: stretch;
}
.country-selector-modal-content-header-title {
  color: #1c2628;
  text-align: center;
  font-family: Lato;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  text-transform: uppercase;
}
.country-selector-modal-content-header-subtitle {
  color: #1c2628;
  text-align: center;
  font-family: Lato;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}
.country-selector-modal-content-body {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
}
.country-selector-modal-content-footer {
  display: flex;
  height: 54px;
  padding: var(--Number, 18.5px) var(--Number, 0px) var(--Number, 19.5px)
    var(--Number, 0px);
  justify-content: center;
  align-items: center;
  align-self: stretch;
  border-radius: 8px;
  background: #00d9bb;
  border: 0;
  color: white;
}
.country-selector-modal-content-footer:hover {
  background-color: #00c4a7;
  border: 0;
}
@media screen and (max-width: 768px) {
  .country-selector-modal {
    width: 90%;
    padding: 24px 24px 16px 24px;
    gap: 24px;
  }
  .country-selector-modal-content-header {
    gap: 16px;
  }
  .country-selector-modal-content-header-title {
    font-size: 20px;
    line-height: 24px;
  }
  .country-selector-modal-content-header-subtitle {
    font-size: 14px;
    line-height: 24px;
  }
  .country-selector-modal-content-footer {
    height: 48px;
    padding: 16px 0 16px 0;
  }
}
