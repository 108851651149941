.wishlist-icon {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.8);
  width: 25px;
  height: 25px;
  border-radius: 100%;
  text-align: center;
  line-height: 25px;
  font-size: 13px;
  color: #01d5bc;
  right: 10px;
  top: 10px;
  z-index: 9;
  display: block;
  border: none;
}
.wishlist-icon i {
  transition: 0.3s;
}
.wishlist-icon.active,
.wishlist-icon:active,
.wishlist-icon:focus,
.wishlist-icon:hover {
  background-color: #01d5bc;
  color: #fff;
  outline: 0 !important;
}
.wishlist-icon .fa-trash-o {
  font-size: 16px;
  color: #3e3e3e;
  margin-top: 4px;
}
.wishlist-icon .fa-heart,
.wishlist-icon.active .fa-heart-o {
  display: none;
}
.wishlist-icon.active .fa-heart {
  display: inline-block;
}
.wishlist-icon.active .fa-trash-o,
.wishlist-icon:active .fa-trash-o,
.wishlist-icon:focus .fa-trash-o,
.wishlist-icon:hover .fa-trash-o {
  color: #fff;
}
.loginpopup {
  background-color: rgba(255, 255, 255, 0.8);
}
