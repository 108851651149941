.ramadan-card-content-container {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.ramadan-card-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-right: 10px;
  margin-left: 10px;
  margin-bottom: 10px;
}

.ramadan-card-content-title {
  font-weight: bolder;
  margin: 0;
  color: #000;
}

.ramadan-card-content-brand {
  margin: 5px 0;
  color: #000;
  font-weight: bold;
}

.ramadan-card-content-m-container {
  margin-right: 10px;
  margin-left: 10px;
}

.ramadan-card-content-models {
  margin: 0px;
  color: #000;
  font-size: 12px;
  font-weight: normal;
}

.ramadan-card-brand-logo {
  width: 50px;
  height: 50px;
  object-fit: contain;
}

.lang-au .ramadan-card-content-models {
  text-align: start;
}

.lang-au .ramadan-card-content-title {
  text-align: start;
}
