.sell-your-car-modal-body {
  display: flex;
  flex-direction: column;
  gap: 41px;
  justify-content: center;
  align-items: center;
}
.sell-your-car-modal-body h2 {
  font-weight: 900;
  line-height: 28px;
  font-size: 24px;
}
.sell-your-car-modal-body span {
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
}
.sell-your-car-modal-buttons {
  width: Fill (416px) px;
  height: 70px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}
@media (max-width: 768px) {
  .sell-your-car-modal-body {
    gap: 24px;
  }
  .sell-your-car-modal-buttons {
    gap: 16px;
  }
}
@media (max-width: 425px) {
  .sell-your-car-modal-body {
    gap: 16px;
  }
  .sell-your-car-modal-buttons {
    gap: 8px;
  }
}
