.sell-your-car {
  position: relative;
  z-index: 1;
  margin-top: -25px;
  margin-bottom: 50px;
}
.sell-your-car:before {
  position: absolute;
  z-index: -1;
  display: block;
  content: '';
  left: 15px;
  right: 15px;
  top: 0;
  bottom: 0;
  background-color: #fff;
  border-radius: 10px;
  border: 1px solid #eaeaea;
}
.errorMessage {
  color: #e7505a;
  padding: 5px 0;
}
.generic-form .form-control,
.generic-form select {
  border: 2px solid #d1d1d1;
  width: 100%;
  height: 37px;
  box-sizing: border-box;
  padding: 2px 10px;
  line-height: 28px;
  font-size: 16px;
  outline: 0;
  border-radius: 0;
  background-color: #fff;
  color: #919191;
}
.generic-form .form-control::placeholder {
  font-size: 16px;
  font-weight: 400;
  color: #919191;
  opacity: 1;
}
.generic-form .form-control:-ms-input-placeholder {
  font-size: 16px;
  font-weight: 400;
  color: #919191;
}
.generic-form .form-control::-ms-input-placeholder {
  font-size: 16px;
  font-weight: 400;
  color: #919191;
}
.generic-form select {
  background: url(/public/images/select-arrow.png) 96% center no-repeat #fff;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
}
.generic-form textarea.form-control {
  min-height: 150px;
}
.generic-form h3,
.generic-form label {
  display: block;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
  line-height: 1.6;
  text-transform: capitalize;
}
.generic-form .buttons {
  text-align: right;
  padding-top: 20px;
}
.generic-form .btn-primary {
  min-width: 300px;
  font-size: 20px;
  border-radius: 5px;
  padding-top: 12px;
  padding-bottom: 12px;
  height: auto;
}
.generic-form .buttons.pt0 {
  padding-top: 0;
}
.generic-form .mobile-number {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.generic-form .mobile-number select {
  width: 28%;
}
.generic-form .mobile-number .form-control {
  width: 70%;
}
.sell-car-form .btn-primary {
  min-width: 151px;
  border-radius: 15px;
  padding-top: 13px;
  padding-bottom: 13px;
}
.sell-your-car.contact-page {
  padding-inline: 10%;
}
.bordered-row.switch {
  margin-block: 0 30px;
  padding-block: 0 15px;
}
.bordered-row.switch::before {
  border-top: 0;
}
.bordered-row {
  position: relative;
  margin-block: 30px;
  padding-block: 30px;
}
.bordered-row::before {
  position: absolute;
  left: -5%;
  right: -5%;
  top: 0;
  bottom: 0;
  content: '';
  border-top: 1px solid #c2c2c2;
  border-bottom: 1px solid #c2c2c2;
}
.custom-control-input:checked ~ .custom-control-label::before,
.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  border-color: #00d5bb;
  background-color: #00d5bb;
}
.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: none;
}
.custom-switch {
  padding-left: 70px;
}
.custom-switch .custom-control-label {
  vertical-align: middle;
  font-weight: 600;
  margin-bottom: 0;
  min-width: 250px;
  text-transform: capitalize;
}
.custom-switch .custom-control-label::before {
  left: -70px;
  top: -2px;
  width: 60px;
  height: 28px;
  border-radius: 30px;
  background-color: #df7975;
  border-color: #df7975;
  outline: 0;
}
.custom-switch .custom-control-label::after {
  top: 0;
  left: -68px;
  width: 24px;
  height: 24px;
  border-radius: 100%;
  background-color: #fff;
  outline: 0;
}
.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #fff;
  -webkit-transform: translateX(32px);
  -ms-transform: translateX(32px);
  transform: translateX(32px);
}
.upload-file .btn,
.upload-file .img-wrap .close {
  font-weight: 300;
  text-align: center;
  cursor: pointer;
}
.upload-file {
  position: relative;
  min-height: 200px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  box-shadow: 0 6px 4px 0 rgb(0 0 0 / 15%);
  border-radius: 5px;
  border: 1px solid #00000026;
  padding: 20px 30px;
  margin-bottom: 20px;
}
.upload-file.imgupload {
  justify-content: flex-start;
}
.upload-file .btn {
  display: inline-block;
  padding: 6px 12px;
  margin-bottom: 0;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid #00d6bc;
  border-radius: 25px;
  width: 80px;
  height: 80px;
  position: relative;
  font-size: 50px;
  line-height: 65px;
  color: #00d6bc;
}
.upload-file .btn-success:not(:disabled):not(.disabled).active,
.upload-file .btn-success:not(:disabled):not(.disabled):active,
.upload-file .show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #00d6bc;
  border-color: #00d6bc;
}
.upload-file .btn-success {
  border: 1px solid #00d6bc;
  background: 0 0;
  color: #666;
  height: 80px;
  width: 80px;
}
.upload-file .fileinput-button input {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  opacity: 0;
  -ms-filter: 'alpha(opacity=0)';
  font-size: 200px;
  direction: ltr;
  cursor: pointer;
}
.upload-file output {
  margin-right: 3%;
}
.upload-file ul.thumb-Images li {
  display: inline-block;
  vertical-align: top;
  margin: 0 10px 15px;
}
.upload-file .img-wrap {
  position: relative;
  display: inline-block;
  font-size: 0;
  height: 130px;
  width: 130px;
  border-radius: 25px;
  border: 2px solid transparent;
  transition: 0.3s;
}
.upload-file .img-wrap:hover {
  border-color: #00d6bc;
}
.upload-file .img-wrap:before {
  content: '';
  display: block;
  padding-top: 100%;
}
.upload-file .thumb {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 8px;
  max-width: 100%;
  height: auto;
  margin: auto;
  max-height: 125px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  -webkit-transform: translateZ(0) scale(1, 1);
}
.upload-file .img-wrap .close {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 100;
  background-color: #00d6bc;
  color: #fff;
  font-size: 20px;
  line-height: 18px;
  width: 20px;
  height: 20px;
  opacity: 1;
  border-radius: 4px;
}
.upload-file .img-wrap:hover .close {
  opacity: 1;
  background-color: red;
}
.upload-file .FileNameCaptionStyle {
  font-size: 14px;
  text-align: center;
  padding-top: 5px;
  color: #00d6bc;
  max-width: 120px;
  margin: 0 auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.upload-file .fileinput-button {
  overflow: hidden;
}
@media (max-width: 1399px) {
  .upload-file {
    padding: 15px;
  }
  .upload-file .thumb {
    height: 120px;
    width: 120px;
  }
}
@media (max-width: 1199px) {
  .upload-file .thumb {
    height: 95px;
    width: 95px;
  }
}
@media screen and (max-width: 767px) {
  .upload-file .thumb {
    height: 100px;
    width: 100px;
  }
  .upload-file .btn-success {
    height: 60px;
    width: 60px;
    font-size: 40px;
    line-height: 45px;
    border-radius: 15px;
    left: 0;
  }
  .upload-file {
    padding: 0;
  }
  .sell-your-car {
    padding-left: 8%;
    padding-right: 8%;
  }
  .generic-form label {
    font-size: 15px;
  }
  .generic-form .buttons {
    text-align: left;
    padding-top: 10px;
    left: 20px;
    font-size: 35px;
    line-height: 45px;
  }
}
@media screen and (max-width: 575px) {
  .generic-form .btn-primary {
    min-width: 200px;
  }
}
