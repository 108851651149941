.user-chat-info .user-chat-unread-count {
  font-weight: 700;
}
.user-chat-info {
  overflow: hidden;
  position: relative;
  line-height: 1.4;
  font-size: 16px;
  color: #989898;
  margin-top: -20px;
  padding-top: 20px;
}
.user-chat-info p {
  max-height: 44px;
  overflow: hidden;
}
.user-chat-info h2 {
  color: #3d3d3d;
  font-size: 18px;
  font-weight: 700;
  text-transform: capitalize;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.user-chat-info h3 {
  color: #00d6bc;
  font-size: 14px;
  font-weight: 700;
  text-transform: capitalize;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.lang-au .user-chat-info {
  text-align: right;
}
.uer-options-drop .dropdown-toggle:active,
.uer-options-drop .dropdown-toggle:focus,
.uer-options-drop .dropdown-toggle:hover {
  box-shadow: none !important;
}
.uer-options-drop .dropdown-toggle::after {
  display: none;
}
.uer-options-drop .dropdown-toggle {
  padding: 0 !important;
  color: #000 !important;
  text-align: center;
  width: 38px;
  min-width: inherit;
  border: 0;
  background-color: transparent !important;
}
.uer-options-drop .dropdown-toggle i {
  margin-right: 0;
  font-size: 24px;
  line-height: 38px;
  font-weight: 300;
}
.uer-options-drop.dropdown .dropdown-menu {
  border-radius: 0;
  width: 150px;
  right: 12px;
  z-index: 999;
  padding-block: 0;
  border-top: 0;
}
.dropdown.uer-options-drop {
  position: absolute;
  right: -6px;
  top: 3px;
  z-index: 99;
}
.dropdown.uer-options-drop .dropdown-menu .dropdown-item.active,
.dropdown.uer-options-drop .dropdown-menu .dropdown-item:active {
  background-color: #f8f9fa;
  outline: 0;
}
.dropdown.uer-options-drop .dropdown-menu .dropdown-item {
  padding: 0.5rem 1rem;
  color: #848484;
  font-size: 13px;
  width: 100%;
  border-top: 1px solid #d7d7d7;
  text-transform: capitalize;
}
@media screen and (min-height: 400px) {
  .modal-open .modal.modal-center {
    display: flex !important;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex !important;
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center !important;
  }
  .modal-open .modal.modal-center .modal-dialog {
    flex-grow: 1;
  }
}
@media screen and (max-width: 767px) {
  .lang-au .dropdown-menu.dropdown-menu-right.show {
    transform: translate3d(0, 28px, 0) !important;
  }
  .dropdown-menu.dropdown-menu-right.show {
    transform: translate3d(-156px, 28px, 0) !important;
  }
}
