.lang-au .form-title:before,
.lang-au .slide-content {
  border-radius: 0 10px 10px 0;
}
body.lang-au {
  direction: rtl;
}
.lang-au a,
.lang-au abbr,
.lang-au acronym,
.lang-au address,
.lang-au applet,
.lang-au article,
.lang-au aside,
.lang-au audio,
.lang-au b,
.lang-au big,
.lang-au blockquote,
.lang-au body,
.lang-au canvas,
.lang-au caption,
.lang-au center,
.lang-au cite,
.lang-au code,
.lang-au dd,
.lang-au del,
.lang-au details,
.lang-au dfn,
.lang-au div,
.lang-au dl,
.lang-au dt,
.lang-au em,
.lang-au embed,
.lang-au fieldset,
.lang-au figcaption,
.lang-au figure,
.lang-au footer,
.lang-au form,
.lang-au h1,
.lang-au h2,
.lang-au h3,
.lang-au h4,
.lang-au h5,
.lang-au h6,
.lang-au header,
.lang-au hgroup,
.lang-au html,
.lang-au iframe,
.lang-au img,
.lang-au ins,
.lang-au kbd,
.lang-au label,
.lang-au legend,
.lang-au li,
.lang-au mark,
.lang-au menu,
.lang-au nav,
.lang-au object,
.lang-au ol,
.lang-au output,
.lang-au p,
.lang-au pre,
.lang-au q,
.lang-au ruby,
.lang-au s,
.lang-au samp,
.lang-au section,
.lang-au small,
.lang-au span,
.lang-au strike,
.lang-au strong,
.lang-au sub,
.lang-au summary,
.lang-au sup,
.lang-au table,
.lang-au tbody,
.lang-au td,
.lang-au tfoot,
.lang-au th,
.lang-au thead,
.lang-au time,
.lang-au tr,
.lang-au tt,
.lang-au u,
.lang-au ul,
.lang-au var,
.lang-au video {
  font-family: Tahoma;
}
.lang-au .accounts-frame .table,
.lang-au .accounts-support .generic-form h3.contact-title,
.lang-au .callus-section h3,
.lang-au .car-find-holder h1,
.lang-au .car-find-holder h2,
.lang-au .car-info-colunm dl dd,
.lang-au .container.details .slider-info > p,
.lang-au .container.details > .row > .col-12 > .deals-section > h2.main-heading,
.lang-au .container.details > .row > .slider-section > .slider-info h2,
.lang-au
  .container.new_cars
  .cars-form-holder
  > .row
  > .col-lg-9.col-md-12
  .col-xl-3.col-md-4.form-group
  > label.form-label,
.lang-au
  .container.new_cars
  section.browse-brands-section.container
  h2.main-heading,
.lang-au
  .container.newcars
  section.browse-brands-section.container
  h2.main-heading,
.lang-au
  .container.sell-your-car
  form.row.generic-form.sell-car-form
  > .col-lg-12
  > h3,
.lang-au .container.usedcars header.title-header > h2,
.lang-au
  .container.usedcars
  > .row
  .cars-form-holder.searchcar
  > .row
  > .col-xl-7.col-md-12
  > form.row.cars-selection-form
  > .col-xl-4.col-md-4.form-group
  > label.form-label,
.lang-au .container.usedetailcar header.details-head .box > h1,
.lang-au .container.usedetailcar header.details-head .box > h2,
.lang-au .container.usedetailcar header.details-head .box > text,
.lang-au
  .container.usedetailcar
  > .row
  > .slider-section.get-deals
  > .deals-detail
  table.table.table-striped.details-table
  tr
  > td,
.lang-au .dealers > header.main-head > .container .col-lg-12 > h1,
.lang-au .dealers > header.main-head > .container .col-lg-12 > p,
.lang-au .deals-advantages .deal-title,
.lang-au .deals-advantages h2,
.lang-au .featured-details h2,
.lang-au .featured-details p,
.lang-au .ms-options-wrap > .ms-options,
.lang-au
  .newcars
  .cars-form-holder
  .col-lg-9.col-md-12
  form.row.cars-selection-form
  > .col-xl-3.col-md-4.form-group
  > label.form-label,
.lang-au .search-details .deals-advantages,
.lang-au .sell_your_car_data header.main-head > .container .col-lg-12 > h1,
.lang-au .sell_your_car_data header.main-head > .container .col-lg-12 > p,
.lang-au .steps-wizard-form,
.lang-au
  .swiper-slide
  > .slide-holder
  > .car-info-colunm
  > .text-holder
  > .text-box
  > dl,
.lang-au
  .swiper-slide
  > .slide-holder
  > .car-info-colunm
  > .text-holder
  > .text-box
  > dl
  > dd,
.lang-au
  .swiper-slide
  > .slide-holder
  > .car-info-colunm
  > .text-holder
  > .text-box
  > span.text,
.lang-au
  .swiper-slide
  > .slide-holder
  > .car-info-colunm
  > .text-holder
  > .text-box
  > span.title-car,
.lang-au
  .swiper-slide
  > .slide-holder
  > .car-info-colunm
  > .text-holder
  > .text-box
  > strong.title-category,
.lang-au article.deals-info-row.usedcars .deals-right > .box,
.lang-au footer#footer > .container .col-sm-10.col-xl-6,
.lang-au form .row.generic-form > .col-lg-12 > h3,
.lang-au
  form#adv-search-form-new
  .row.generic-form
  > .col-md-4.col-sm-6
  > .pricerange-box
  > label,
.lang-au
  form#adv-search-form-new
  .row.generic-form
  > .col-md-4.col-sm-6
  > label,
.lang-au
  form#adv-search-form-used
  > .row.generic-form
  .col-xl-12.form-group
  ul.switch-buttons.list-inline,
.lang-au label,
.lang-au
  main.online_showroom
  .container.featured-details-page
  > .row
  > .col-12
  form.row.cars-selection-form.showroom
  label.form-label,
.lang-au
  main.online_showroom
  article.car-info-colunm
  > .text-holder
  > .text-box
  > dl,
.lang-au
  main.online_showroom
  article.car-info-colunm
  > .text-holder
  > .text-box
  > dl
  > dd,
.lang-au
  main.online_showroom
  article.car-info-colunm
  > .text-holder
  > .text-box
  > span,
.lang-au
  main.online_showroom
  article.car-info-colunm
  > .text-holder
  > .text-box
  > span.title-car
  .lang-au
  form#adv-search-form-used
  > .row.generic-form
  .col-md-4
  .pricerange-box
  > label,
.lang-au
  main.online_showroom
  article.car-info-colunm
  > .text-holder
  > .text-box
  > strong.title-category,
.lang-au main.online_showroom > header.main-head .col-lg-12 > h1,
.lang-au main.online_showroom > header.main-head .col-lg-12 > p,
body.lang-au
  .nav-column
  li.nav-item
  .menu-dopdown
  > ul.dopdown-nav
  > li.nav-item
  > a.nav-link,
body.lang-au .nav-item {
  text-align: right;
}
.lang-au
  .swiper-slide
  > .slide-holder
  > .car-info-colunm
  > .text-holder
  > .text-box
  > dl
  > dt
  .lang-au
  main.online_showroom
  article.car-info-colunm
  > .text-holder
  > .text-box
  > dl
  > dt,
.lang-au
  article.deals-info-row.usedcars
  .deals-right
  > .box
  dl.cars-info-list
  dt {
  float: right;
  margin-left: 2%;
}
.lang-au .car-info-colunm .logo-img,
.lang-au .container.usedcars header.title-header > .drop-page-holder {
  left: 0;
  right: unset;
}
.lang-au .container.usedcars header.title-header {
  padding-right: 20px;
  padding-left: 240px;
}
.lang-au .car-info-colunm .text-box,
.lang-au
  .container.new_cars
  section.browse-brands-section.container
  article.car-info-colunm
  .text-holder
  > .text-box {
  padding: 0 0 13px 55px;
  text-align: right;
}
.lang-au
  .swiper-slide
  > .slide-holder
  > .car-info-colunm
  > .img-holder
  > .auto-imgbox
  > span.badge {
  right: 5px;
  left: unset;
}
.lang-au
  .container.usedcars
  > .row
  .cars-form-holder.searchcar
  > .row
  > .col-xl-7.col-md-12
  > form.row.cars-selection-form
  .lang-au
  .info-block.tabs
  .cars-slider-block
  .swiper-button-prev,
.lang-au
  .swiper-slide
  > .slide-holder
  > .car-info-colunm
  > .img-holder
  > .auto-imgbox
  > a.wishlist-icon {
  left: 10px;
  right: unset;
}
.lang-au article.deals-info-row.usedcars > .deals-left {
  border-left: 1px solid #ddd;
  border-right: 0;
}
.lang-au .cars-info-list dt,
.lang-au
  .container.usedetailcar
  > .row
  > .slider-section.get-deals
  .buttons-holder
  a.btn-back,
body.lang-au .business-inquiry-chkbox,
body.lang-au .business-inquiry-txt,
body.lang-au .errorMessage,
body.lang-au
  label:not(.custom-control-label):not(.range-control-label):not(
    .mobile_code
  ):not(.contact-attach-lbl) {
  float: right;
}
.lang-au .car-find-holder a.adv-search,
.lang-au
  .container.usedetailcar
  > .row
  > .slider-section.get-deals
  .buttons-holder
  span.refrance,
body.lang-au .btn-send {
  float: left;
}
.lang-au
  .container.usedcars
  > .row
  .cars-form-holder.searchcar
  > .row
  > .col-xl-7.col-md-12
  > form.row.cars-selection-form
  > .col-xl-12.form-group.buttons.text-right {
  text-align: left !important;
}
.lang-au .form-title h2 {
  padding: 0 0 0 10%;
  text-align: right;
}
.lang-au .form-title:before {
  right: 0;
  left: 95px;
}
.lang-au .form-title h2:before {
  left: auto;
  right: 100%;
  margin-right: -80px;
  margin-left: 0;
  border-style: solid;
  border-width: 1px 0 229px 100px;
  border-color: transparent transparent #464647;
}
.lang-au .form-title.search:after {
  left: auto;
  right: 10px;
}
.lang-au .form-group select {
  background-position-x: 5%;
}
.lang-au .btn-select li {
  float: right;
  padding-left: 0;
  padding-right: 35px;
}
.cars-form-holder.searchcar
  > .row
  .cars-selection-form
  > .col-xl-4.col-md-4.form-group {
  display: flex;
  flex-direction: column;
}
.lang-au .btn-select li img,
.lang-au .custom-control-input,
.lang-au .deals-nav li .ico,
.lang-au .infomation-nav li:before,
.lang-au .lang-button img {
  left: auto;
  right: 0;
}
.lang-au .search-form .btn-submit {
  right: auto;
  left: -1px;
  border-radius: 5px 0 0 5px;
}
.lang-au .search-form .form-control {
  padding: 0 15px 0 60px;
}
.lang-au .lang-button {
  padding-left: 0;
  padding-right: 37px;
  text-align: right;
  max-width: 115px;
}
.lang-au .info-block.tabs .cars-slider-block .swiper-button-next {
  left: 45px;
  right: auto;
}
.lang-au .info-block.tabs ul#myTab li.nav-item {
  margin: 0 0 0 29px;
}
.lang-au .container.usedcars button.btn.btn-advance.adv-search {
  margin: 3px 4px 0 0;
}
.lang-au span.qar {
  float: left;
  margin-right: 5px;
}
.lang-au
  form#adv-search-form-new
  .row.generic-form
  > .col-md-4.col-sm-6
  > .ms-options-wrap
  > button {
  text-align: right;
  padding: 4px 10px 4px 20px;
}
.lang-au
  form#adv-search-form-new
  .row.generic-form
  > .col-md-4.col-sm-6
  > .ms-options-wrap
  > button::after {
  left: 5px;
  right: unset;
}
.lang-au
  .container.new_cars
  .cars-form-holder
  > .row
  .col-lg-3.col-md-12.form-title
  > h2,
.lang-au .newcars .cars-form-holder .col-lg-3.col-md-12.form-title > h2 {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}
.lang-au .container.details > .row > header.details-header > .brandlogo {
  left: 15px;
  right: unset;
}
.lang-au
  .container.details
  > .row
  > .col-12
  > .deals-section
  .deals-advantages
  > span.year,
.lang-au .container.details > .row > header.details-header > .holder,
.lang-au
  footer#footer
  > .container
  > .row.footer-holder
  > .col-xl-4.mt-4.mt-xl-0
  > ul.social-icons {
  float: right;
  text-align: right;
}
.lang-au .container.details > .row > .slider-section > .slider-info {
  border-radius: 10px 0 0 10px;
  margin-right: unset;
  margin-left: 2%;
}
.lang-au .container.details > .row > .slider-section > ul.slider-info-nav > li {
  text-align: right;
  padding-left: unset;
  padding-right: 35px;
}
.lang-au
  .container.details
  > .row
  > .slider-section
  > ul.slider-info-nav
  > li
  > i {
  left: unset;
  right: 0;
}
.lang-au .container.details > .row > .col-12 > .deals-section span.badge-icon,
.lang-au main.online_showroom .dealsimg2 > span.badge-icon {
  right: 6px;
  left: unset;
}
.lang-au
  .container.details
  > .row
  > .col-12
  > .deals-section
  .deals-advantages
  .small-logos {
  left: 30px;
  right: unset;
}
.lang-au
  .container.details
  > .row
  > .col-12
  > .deals-section
  .deals-advantages
  > h2,
.lang-au
  .container.details
  > .row
  > .col-12
  > .deals-section
  .deals-advantages
  > span.year,
.lang-au
  .container.details
  > .row
  > .col-12
  > .deals-section
  .deals-advantages
  > strong.deal-title {
  padding-right: unset;
  padding-left: 115px;
  text-align: right;
  margin: unset;
}
.lang-au .container.details > .row > .col-12 > .deals-section > .deals-left,
.lang-au main.online_showroom article.deals-info-row > .deals-left {
  border-left: 1px solid #ddd;
  border-right: unset;
}
.lang-au
  .container.details
  > .row
  > .col-12
  > .deals-section
  > .deals-left
  > .dealsimg2,
.lang-au main.online_showroom > .deals-left > .dealsimg2 {
  border-radius: 0 8px 8px 0;
}
.lang-au div#wrapper > header#header > .container > .header-holder strong.logo {
  right: 20px;
  left: unset;
}
.lang-au #footer .btn-shop,
.lang-au #header .btn-shop {
  left: 0;
  right: auto;
}
.lang-au .cars-selection-form .btn-primary,
.lang-au .login-button img {
  margin-right: 0;
  margin-left: 5px;
}
.lang-au .switch .col-md-3.col-sm-4.form-group > label,
.lang-au .switch ul.switch-buttons.list-inline > li {
  text-align: right;
  direction: ltr;
}
.lang-au
  .sell_your_car_data
  .container.sell-your-car
  form.row.generic-form.sell-car-form
  .col-xl-12.form-group
  label {
  text-align: right;
  width: -webkit-fill-available;
}
.lang-au #header .form-opener {
  right: auto;
  left: 55px;
}
.lang-au .swiper-button-next {
  right: auto;
  left: 45px;
}
.lang-au .edit-deals,
.lang-au .paging-holder .counts,
.lang-au .swiper-button-prev {
  right: auto;
  left: 10px;
}
.lang-au .cars-form-holder .btn-advance.newcar {
  left: 15px;
  right: auto;
}
.lang-au .paging-holder {
  padding-left: 65px;
  padding-right: 15px;
}
.lang-au .fitlers-drop.dropdown {
  margin-left: 10px;
  margin-right: 0;
}
.lang-au .deals-right .cars-info-list {
  margin-right: 7%;
  margin-left: 0;
}
.lang-au .deals-right .box {
  padding-right: 0;
  padding-left: 12px;
}
.lang-au .deals-left .img-box {
  margin-left: auto;
}
.lang-au .usedcars-rows .deals-advantages .deal-title {
  padding-right: 0;
  padding-left: 60px;
}
.lang-au .deals-advantages h2 {
  margin-right: 0;
  margin-left: 65px;
}
.lang-au .usedcars-rows .deals-advantages .small-logos,
.lang-au .user-chat-date {
  right: auto;
  left: 0;
}
.lang-au .car-info-colunm dl dt {
  float: right;
  padding-right: 0;
  padding-left: 8px;
  direction: rtl;
}
.lang-au .car-info-colunm dl {
  margin: 0 0 0 -55px;
}
.lang-au .deals-right .frame strong {
  font-size: 19px;
}
.lang-au .custom-switch {
  padding-left: 0;
  padding-right: 70px;
}
.lang-au .custom-switch .custom-control-label::before {
  left: auto;
  right: -70px;
}
.lang-au .custom-switch .custom-control-label::after {
  left: auto;
  right: -68px;
}
.lang-au
  .custom-switch
  .custom-control-input:checked
  ~ .custom-control-label::after {
  -webkit-transform: translateX(-32px);
  -ms-transform: translateX(-32px);
  transform: translateX(-32px);
}
.lang-au .infomation-nav li {
  padding-left: 0;
  padding-right: 32px;
}
.lang-au .slick-slider-big,
.lang-au .slick-slider-big2,
.lang-au .time-holder,
body.lang-au .form-control.custom-tel {
  direction: ltr;
}
.lang-au .comment-header-profile,
.lang-au .comment-reply-profile,
.lang-au .slider-info-nav li i {
  margin-right: 0;
  margin-left: 10px;
}
.lang-au .deals-nav li {
  position: relative;
  text-align: right;
}
.lang-au .deals-left .deals-nav {
  margin-top: 17px;
  width: 100%;
}
.lang-au .slider-info .btn-secondary {
  left: auto;
  right: 30px;
}
.lang-au .comment-box {
  margin: 0 auto 0 0;
  text-align: right;
}
.lang-au .user-box-comment .btn-secondary,
.user-box-reply .btn-secondary {
  float: left;
  margin: 10px 0 0 20px;
}
.lang-au .user-box-reply {
  margin-left: 0;
  margin-right: 40px;
}
.lang-au .user-box-reply label {
  width: 180px;
}
.lang-au .accounts-aside {
  border-right: 0;
  border-left: 6px solid #ebe9e9;
}
.lang-au .step-app > .step-steps > li::before {
  left: auto;
  right: 20px;
}
.lang-au .steps-wizard-modal .close-standard-modal {
  right: auto;
  left: 15px;
}
.lang-au .reply-box {
  margin: 0 auto 0 -12px;
  text-align: right;
}
.lang-au .information-nav li {
  padding-left: 0;
  padding-right: 24px;
  background-position: 100% center;
}
.lang-au .deals-advantages .small-logos {
  left: 30px;
  right: auto;
}
.lang-au .deals-advantages .deal-title {
  padding-right: 0;
  padding-left: 85px;
}
.lang-au .accounts-support .generic-form .buttons {
  text-align: left;
}
.lang-au .ms-options-wrap > .ms-options .ms-selectall {
  padding: 10px 30px 10px 0;
}
.lang-au .ms-options-wrap > .ms-options .ms-selectall:before {
  left: auto;
  right: 5px;
}
.lang-au .ms-options-wrap > .ms-options > ul li {
  padding: 5px 45px 5px 0;
}
.lang-au .ms-options-wrap > .ms-options > ul li label:before {
  left: auto;
  right: -16px;
}
.lang-au .ms-options-wrap > .ms-options > ul label {
  padding-left: 0;
  padding-right: 13px;
}
.lang-au .deals-advantages {
  padding-inline: 20px 10px;
}
.lang-au .modal-completed .steps-wizard-form .btn-primary {
  margin-right: 0;
  margin-left: 50px;
}
@media (min-width: 1200px) {
  .lang-au #header .header-holder {
    padding-left: 20px;
    padding-right: 24%;
  }
  .lang-au #header .logo {
    left: auto;
    right: 20px;
  }
  .lang-au #header .nav-holder:before {
    left: -9999px;
    right: 0;
  }
  .lang-au .search-form {
    padding: 0 0 0 355px;
  }
  .lang-au #header .search-info-bar {
    right: auto;
    left: 0;
  }
  .lang-au .form-title.search h2 {
    padding-left: 0;
    padding-right: 225px;
    font-size: 42px;
    margin-block: 0 -32px;
  }
  .lang-au .form-title.search h2 .for {
    font-size: 26px;
    margin-right: 0;
    margin-left: 8px;
  }
}
@media (max-width: 1399px) {
  .lang-au .cars-form-holder.searchcar .form-title h2:before {
    margin-right: -81px;
    top: -96px;
  }
  .lang-au .form-title.search h2 {
    font-size: 35px;
  }
}
@media (max-width: 991px) {
  .lang-au .form-title:before {
    right: -9999px;
    left: -9999px;
  }
}
@media (min-width: 768px) {
  .lang-au .arabic-offset.offset-md-1 {
    margin-left: 0;
    margin-right: 8.3333333333%;
  }
  .lang-au .navbar-nav > .nav-item:last-child {
    margin-right: 29px;
  }
  .lang-au .navbar-nav .nav-item:first-child {
    margin-right: 0;
  }
}
body.lang-au h2.detail-swtich > span.ar-right {
  float: right;
  margin-left: 5px;
}
body.lang-au .nav-column li.nav-item .menu-dopdown {
  right: 0;
}
body.lang-au button.nav-link.button-as-link {
  float: right !important;
}
body.lang-au span.egine-type-badge {
  left: 6px;
  right: unset;
}
body.lang-au .old-new-toggle-wrapper {
  left: 5px;
  right: unset !important;
}
body.lang-au .search-wrapper > div > div > div {
  padding-right: 6px !important;
}
@media (max-width: 1199px) {
  .lang-au .deals-right .box {
    width: 70%;
  }
  .lang-au .form-title h2 {
    padding-left: auto;
    padding-right: 0;
  }
  .lang-au .login-button {
    float: left;
  }
  .lang-au #footer .btn-shop,
  .lang-au #header .btn-shop {
    margin: 0 20px 0 0;
    float: left;
  }
  .lang-au #header .left-align:after {
    display: block;
    content: '';
    clear: both;
  }
  .lang-au .lang-select {
    float: right;
  }
  body.lang-au .search-wrapper-mobile {
    top: 59.5px !important;
    left: 112px;
    right: unset !important;
  }
}
body.lang-au .range-control-label {
  padding-right: 68px;
}
body.lang-au .arabic_km {
  white-space: nowrap;
  min-width: 150px;
}
