.ramadan-details {
  position: relative;
  margin-inline: 0;
  font-size: 16px;
  line-height: 1.6;
  color: #3d3d3d;
}
.information-nav li img {
  margin: 0;
}
.information-nav li {
  display: flex;
  align-items: center;
  margin-bottom: 2px;
  padding-left: 24px;
  background-size: 13px;
  gap: 10px;
}
.ramadan-details h1 {
  color: #3d3d3d;
  font-size: 29px;
  line-height: 1.3;
  margin-bottom: 15px;
  font-weight: 800;
  text-transform: uppercase;
}

.ramadan-details h2 {
  color: #3d3d3d;
  font-size: 24px;
  line-height: 1.3;
  margin-bottom: 10px;
  font-weight: 800;
  text-transform: capitalize;
}

.ramadan-details strong {
  font-weight: 800;
}

.ramadan-details p {
  margin-bottom: 15px;
}

.ramadan-details .detail {
  font-size: 16px;
}

.ramadan-details .detail p {
  white-space: pre-wrap;
}

.ramadan-offer-btn {
  min-width: 250px;
}

.ramadan-inquiry {
  height: 40px !important;
  width: 100% !important;
  background-color: #00d6bc !important;
  text-transform: capitalize !important;
}

.ramadan-featured-details {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  width: 100%;
}

.benefits ul p {
  margin: 0;
}

.benefits h3 {
  text-align: start;
}

.benefits ul {
  padding: 0;
  column-gap: '15px';
}

@media screen and (max-width: 992px) {
  .featured-details ul.information-nav {
    flex-wrap: nowrap !important;
    height: unset !important;
  }
  .information-nav li {
    align-items: start;
  }
  .information-nav li img {
    margin-top: 5px;
  }
  .ramadan-featured-details {
    flex-direction: column;
    gap: 20px;
  }
  .ramadan-details {
    padding: 35px 20px;
    font-size: 17px;
  }
  .ramadan-details h1 {
    font-size: 26px;
    margin-bottom: 12px;
  }

  .featured-details h2 {
    font-size: 23px;
  }
}

.lang-au .ramadan-details h1 {
  text-align: start;
}

.lang-au .ramadan-details h2 {
  text-align: start;
}

.lang-au .ramadan-details p {
  text-align: start;
}
