.article-details-info {
  position: relative;
  overflow: hidden;
  color: #3d3d3d;
  line-height: 1.6;
  font-size: 15px;
  padding: 0 20px;
}
.article-details-info h2,
.article-details-info h1 {
  font-size: 42px;
  line-height: 1.2;
  margin-bottom: 10px;
  color: #3d3d3d;
  font-weight: 800;
}
.article-description ol,
.article-description ul {
  list-style: unset;
}
.article-description img {
  margin: unset;
  display: unset;
}
.article-date-nav {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  color: #848484;
  margin-bottom: 20px;
  font-size: 15px;
}
.article-date-nav li {
  margin-right: 10px;
}
@media (max-width: 1199px) {
  .article-details-info {
    font-size: 20px;
    padding-inline: 10px;
  }
  .article-details-info h2,
  .article-details-info h1 {
    font-size: 40px;
  }
}
@media (max-width: 767px) {
  .article-details-info img {
    width: 100% !important;
    height: auto !important;
  }
}
@media (max-width: 480px) {
  .article-details-info h2,
  .article-details-info h1 {
    font-size: 24px !important;
  }
  .article-details-info p {
    font-size: 14px !important;
  }
}
.lang-au .article-details-info h2,
.lang-au .article-details-info h1,
.lang-au .article-details-info p {
  text-align: right;
}
