.cars-selection-form {
  align-items: flex-end;
}
.cars-form-holder .btn-advance {
  text-decoration: underline;
}
.cars-form-holder .btn-advance {
  color: #fff;
  padding: 2px;
  border: 0;
  font-size: 12px;
  background: 0 0;
}
.cars-form-holder .btn-advance.newcar {
  position: absolute;
  right: 15px;
  bottom: -25px;
  font-size: 14px;
}
.cars-form-holder .btn-advance:active,
.cars-form-holder .btn-advance:focus,
.cars-form-holder .btn-advance:hover {
  text-decoration: none;
  background: 0 0;
  outline: 0;
  box-shadow: none;
  color: white;
}
.cars-selection-form label {
  display: block;
  font-size: 18px;
  margin-bottom: 3px;
  color: #fff;
  font-weight: 700;
  letter-spacing: 1px;
}
.MuiSlider-thumb {
  color: transparent;
  background: url('../../common/images/swipeButton.webp') no-repeat;
  background-size: cover;
}
.MuiSlider-root {
  color: #606464 !important;
}
.MuiButtonBase-root {
  outline: none !important;
}
.cars-selection-form .form-control {
  height: 30px;
  background-color: #fff;
  border: 0;
  padding: 5px 10px;
  font-size: 13px;
  border-radius: 3px;
  color: #919191;
}
.cars-selection-form .input-group.date {
  padding-left: 20px;
  padding-right: 5px;
  background-color: #fff;
  border-radius: 5px;
}
.cars-selection-form .input-group-addon {
  font-size: 18px;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 30px;
  line-height: 30px;
  z-index: 99;
  color: #999;
  text-align: center;
}
.cars-selection-form select {
  display: block;
  width: 100%;
  padding: 4px 7px 5px;
  border: 0;
  border-radius: 3px;
  font-size: 15px;
  outline: 0;
  height: 30px;
  color: #919191;
  background: url(/public/images/select-arrow.png) 95% center no-repeat #fff;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
}
.cars-selection-form .btn-primary {
  min-width: 105px;
  padding: 3px 10px 4px;
  background-color: #464647;
  border-color: #464647;
  margin-right: 5px;
  height: 35px;
}
.cars-selection-form .btn-primary {
  border-radius: 10px;
  color: #fff;
  font-size: 15px;
  font-weight: 700;
  text-transform: capitalize;
  outline: 0;
  text-align: center;
}
.cars-selection-form .btn-primary:active,
.cars-selection-form .btn-primary:focus,
.cars-selection-form .btn-primary:hover {
  background-color: #606060 !important;
  border-color: #606060 !important;
}
.cars-selection-form.showroom select {
  padding: 2px 10px;
  border: 2px solid #d1d1d1;
  border-radius: 0;
  font-size: 16px;
  outline: 0;
  height: 37px;
}
.cars-selection-form.showroom label {
  font-size: 18px;
  color: #3d3d3d;
  text-transform: capitalize;
}
.cars-selection-form.showroom {
  margin-inline: -1%;
  align-items: flex-end;
}
.cars-selection-form.showroom .btn-clear {
  color: #3d3d3d;
  font-size: 15px;
  border-color: #3d3d3d;
}
.cars-selection-form.showroom .btn-clear:hover {
  color: #fff;
  border-color: #04e4cc;
  background-color: #04e4cc;
}
.cars-selection-form.showroom .form-group {
  width: 24.6%;
  margin-inline: 1%;
}
.cars-selection-form.showroom .form-group.buttons {
  text-align: center;
  width: 18%;
}
.btn-clear {
  border-radius: 10px;
  color: #fff;
  font-size: 15px;
  font-weight: 700;
  text-transform: capitalize;
  outline: 0;
  text-align: center;
  padding: 5px 10px;
  background-color: transparent;
  border: 1px solid #fff;
  transition: 0.3s;
}
.btn-clear:active,
.btn-clear:focus,
.btn-clear:hover {
  color: #464647;
  background-color: #fff;
}
.pricerange-box {
  margin-block: 15px 25px;
}
@media (min-width: 1400px) {
  .cars-selection-form .btn-primary {
    min-width: 130px;
  }
}
@media (max-width: 1199px) {
  .btn-clear {
    padding: 6px 10px;
  }
  .cars-selection-form.showroom .form-group {
    width: 23.5%;
  }
  .cars-selection-form.showroom .form-group.buttons {
    width: 21%;
  }
  .cars-selection-form .btn-primary {
    padding: 6px 10px;
  }
}
@media (max-width: 991px) {
  .cars-selection-form.showroom .form-group.buttons {
    width: 35%;
  }
  .cars-selection-form.showroom .form-group {
    width: 30.5%;
  }
}
@media (max-width: 767px) {
  .cars-selection-form.showroom .form-group {
    width: 30.5%;
  }
  .cars-selection-form.showroom .form-group.buttons {
    width: 26%;
  }
}
@media (max-width: 600px) {
  .MuiSlider-thumb {
    color: transparent;
    background: url('../../common/images/swipeButton.webp') no-repeat;
    background-size: cover;
  }
  .MuiSlider-root {
    color: #606464 !important;
    border: 0 !important;
    outline: none !important;
  }
  .MuiButtonBase-root {
    outline: none !important;
    border: 0;
  }
  .makeStyles-bottomIcons-201 {
    color: #606464 !important;
    font-size: 24px !important;
    border: 0 !important;
    outline: none !important;
  }
  .makeStyles-bottomIcons-57 {
    color: #606464 !important;
    font-size: 10% !important;
    outline: none !important;
    border: 0 !important;
  }
  .WithStyles\(ForwardRef\(Slider\)\)-thumb-60 {
    width: 12px !important;
    height: 12px !important;
    margin-top: -6px !important;
  }
  .WithStyles\(ForwardRef\(Slider\)\)-thumb-66 {
    margin-top: -7px !important;
  }
  .makeStyles-controlIcons-60 {
    background: none !important;
    border: 0 !important;
    outline: none !important;
    font-size: 25px !important;
  }
  .makeStyles-bottomIcons-61 {
    background: none !important;
    border: 0 !important;
    outline: none !important;
  }
}
@media (max-width: 595px) {
  .cars-selection-form.showroom .form-group,
  .cars-selection-form.showroom .form-group.buttons {
    width: 100%;
  }
}
@media (max-width: 479px) {
  .cars-selection-form.showroom .form-group,
  .cars-selection-form.showroom .form-group.buttons {
    width: 100%;
  }
  .cars-selection-form .btn-primary {
    min-width: 50px !important;
  }
}
