.deals-detail-info {
  width: 35%;
  margin-left: 3%;
}
.deals-detail-info .btn-secondary.showroom,
.deals-detail-info .btn-secondary.showroom-rent {
  border-color: #464647;
  font-size: 17px;
  padding: 10px 20px;
  background-color: #464647;
}
.deals-detail-info .buttons-holder {
  text-align: center;
  padding-top: 20px;
}
.deals-detail-info .btn-secondary {
  font-size: 22px;
  border-radius: 10px;
}
.deals-detail-info .box {
  position: relative;
  padding-top: 10px;
}
.loginpopup {
  background-color: rgba(255, 255, 255, 0.8);
}
.deals-detail-info .btn-secondary.showroom-rent {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  border-radius: 5px;
  gap: 10px;
}
.features-holder {
  margin-top: 20px;
}
.deals-detail-info .btn-secondary.showroom-rent:active,
.deals-detail-info .btn-secondary.showroom-rent:focus,
.deals-detail-info .btn-secondary.showroom-rent:hover,
.deals-detail-info .btn-secondary.showroom:active,
.deals-detail-info .btn-secondary.showroom:focus,
.deals-detail-info .btn-secondary.showroom:hover {
  background-color: #606060;
  border-color: #606060;
}
.deals-detail-info .btn-secondary.share {
  min-width: 54px;
}
.deals-detail-info .buttons-holder .holder {
  position: relative;
  margin-bottom: 15px;
}
.deals-detail-info .refrance {
  font-size: 13px;
  float: right;
}
.lang-au .deals-detail-info .refrance {
  float: left;
}
.get-deals .slider-holder {
  width: 62%;
}
.lang-au .description-holder {
  text-align: right;
}
.description-holder {
  margin-top: 20px;
}
deals-detail-info {
  text-align: right;
}
.lang-au section.deals-detail-info {
  margin-left: unset;
  margin-right: 3%;
  text-align: right;
}
.deals-detail-rent {
  margin-bottom: 20px;
}
.deals-detail,
.deals-detail-rent {
  padding: 15px 0;
  background-color: #fff;
}
.deals-detail {
  border: 1px solid #eaeaea;
  border-radius: 15px;
}
.details-head {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 5px 20px 20px 25px;
}
.details-head .box {
  width: 47%;
  padding-right: 10px;
}
.details-head h1 {
  font-size: 20px;
  line-height: 1.3;
  margin-bottom: 0;
  font-weight: 400;
  color: #726c6c;
}
.details-head h2 {
  font-size: 30px;
  line-height: 1.3;
  margin-bottom: 0;
  color: #424040;
  font-weight: 700;
}
.details-head text {
  font-size: 30px;
  line-height: 1.3;
  margin-bottom: 0;
  color: #424040;
  font-weight: 700;
}
.details-head .box2 {
  width: 52%;
}
.price-qar span {
  vertical-align: middle;
}
.price-qar {
  font-size: 25px;
  font-weight: 900;
  display: block;
  color: #00d6bc;
  letter-spacing: 1px;
  margin-bottom: 5px;
  margin-top: 8px;
  position: relative;
  text-align: right;
}
.price-qar span {
  max-width: 140px;
  line-height: 1;
  word-break: break-all;
  display: inline-block;
}
.price-qar sub {
  font-weight: 600;
  font-size: 16px;
}
.used-cars-buttons-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
}
.used-cars-buttons {
  display: flex;
  gap: 10px;
}
.show-number-used-car {
  outline: 0;
}
.whatsapp-btn-used-car {
  background-color: #25d366 !important;
  color: #fff !important;
  font-size: 0px !important;
  outline: 0 !important;
}
.get-deal-used-car {
  height: 40px;
  width: 100%;
  background-color: #00d6bc !important;
  text-transform: capitalize !important;
  text-align: center !important;
}
.details-table tbody tr td:last-child {
  padding-left: 0;
}
.details-table {
  margin-bottom: 0;
}
.details-table tbody tr td:first-child {
  font-weight: 800;
  width: 200px;
  padding-left: 25px;
}
.details-table tr td {
  padding: 7px 20px;
  font-size: 16px;
}
.details-table tfoot tr td {
  padding-top: 10px;
  padding-bottom: 15px;
  font-size: 13px;
  color: #b3b3b3;
  border-top: 0;
}
.table-striped strong {
  font-weight: 700;
}
.table-striped tbody tr:nth-of-type(odd) {
  background-color: #fff;
}
.table-striped tr td.time {
  text-align: right;
}
.table-striped td time {
  color: #999;
  display: inline-block;
  vertical-align: top;
  margin-top: -10px;
  font-size: 14px;
}
.comments-detail {
  width: 1274px;
  display: block;
}
.comment-facies {
  display: flex;
  column-gap: 13%;
  background-color: #f8f8f8;
  border-radius: 26px;
  width: 145px;
  padding-inline: 10px;
  padding-block: 4px;
  border: 1px solid #d3d3d3;
  position: relative;
}
.comment-facies:before {
  position: absolute;
  content: '';
  height: 12px;
  width: 12px;
  border-bottom: 1px solid #d3d3d3;
  border-right: 1px solid #d3d3d3;
  background-color: #f8f8f8;
  transform: rotate(45deg);
  left: 18%;
  bottom: -18%;
}
.comment-facies li:first-child {
  z-index: 1;
}
.comment-dealear-image-holder {
  width: 300px;
}
.comment-dealear-image-holder img {
  object-fit: contain;
}
.comment-dealear-image-holder {
  margin-left: auto;
  margin-right: auto;
}
.comment-header-heading {
  font-size: 18px;
  font-weight: 800;
}
.comment-header {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 10px;
}
.comment-box {
  padding: 0 20px 20px;
  margin: 0 0 0 auto;
}
.comments-holder-detail {
  widows: 100%;
}
.reaction-blocks {
  display: none;
  position: absolute;
  top: -50px;
  left: -5px;
  padding-bottom: 20px;
  z-index: 99;
}
.sharenav,
.sharenav-rent {
  display: flex;
}
.sharenav {
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.sharenav-rent > li,
.sharenav > li {
  margin-inline: 5px;
  position: relative;
}
.sharenav .reaction-blocks {
  top: 100%;
  left: -183px;
  padding-bottom: 0;
  padding-top: 10px;
}
.sharenav .comment-facies {
  column-gap: inherit;
  border-radius: 15px;
  width: 248px;
  padding-inline: 10px;
  padding-block: 7px;
  justify-content: center;
}
.sharenav .comment-facies:before {
  bottom: 0;
  right: 30px;
  top: -7px;
  left: auto;
  z-index: -1;
  border-top: 1px solid #d3d3d3;
  border-left: 1px solid #d3d3d3;
}
.sharenav .comment-facies li {
  margin-inline: 6px;
}
.btn-back {
  text-decoration: underline;
  vertical-align: top;
  display: inline-block;
  color: #3d3d3d;
  font-weight: 700;
  float: left;
}
.btn-back i {
  vertical-align: middle;
  display: inline-block;
  margin-right: 5px;
}
.lang-au .btn-back i {
  margin-right: 0;
  margin-left: 5px;
  transform: rotate(180deg);
  float: right;
}
.lang-au .btn-back {
  float: right;
}
@media (max-width: 1399px) {
  .price-qar {
    font-size: 24px;
  }
  .price-qar sub {
    font-size: 14px;
  }
}
@media (max-width: 1199px) {
  .details-table tr td {
    padding: 6px 10px;
    font-size: 14px;
  }
  .details-table tfoot tr td {
    padding-top: 25px;
  }
  .deals-detail-info .btn-secondary {
    font-size: 18px;
  }
  .deals-detail-info {
    width: 100%;
  }
  .details-head h2 {
    font-size: 26px;
  }
  .details-head text {
    font-size: 26px;
  }
  .details-head h1 {
    font-size: 16px;
  }
  .details-head .box {
    width: 40%;
  }
  .details-head .box2 {
    width: 58%;
  }
}
@media (max-width: 991px) {
  .get-deals .slider-holder {
    width: 100%;
    margin-bottom: 30px;
  }
}
@media (max-width: 767px) {
  .comment-dealear-image-holder {
    margin-left: auto;
    margin-right: auto;
  }
  .deals-detail-info {
    margin-left: 0;
  }
  .lang-au .price-qar {
    text-align: left;
  }
}
@media (max-width: 480px) {
  .details-head {
    padding: 5px 10px 20px !important;
  }
}
