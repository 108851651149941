@media screen and (max-width: 767px) {
  .thank-you-page.d-flex.flex-column.justify-content-center.align-items-start {
    padding: 20px;
  }
  .lang-au
    .thank-you-page.d-flex.flex-column.justify-content-center.align-items-start {
    text-align: right;
  }
  .lang-au .thank-you-page .btn-primary {
    margin-right: 0;
  }
}
