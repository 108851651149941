#wrapper:has(div.landing-page-wrapper) {
  padding: 0;
  background-color: #151515;
  display: flex;
  flex-direction: column;
  align-items: start;
}
.oac-logo {
  width: 224px;
}
div.landing-page-wrapper * {
  font-family: 'Lato', Sans-serif;
}
.green-gradient {
  background: linear-gradient(180deg, #06a28e 0%, #151515 200px);
  margin-top: 85px;
}
.top-section-title {
  font-weight: 600;
  font-size: 32px;
  color: #fff;
}
.header-wrapper {
  background-color: #151515;
  padding: 10px;
  position: fixed;
  width: 100%;
  height: 85px;
}
.download-app-button {
  background-color: #e1e1e1;
  color: #000000;
  font-size: 15px;
  font-weight: 600;
  line-height: 15px;
  padding: 12px 24px;
  border-radius: 24px;
  cursor: pointer;
}
.top-section-image {
  height: auto;
}
.header-action-buttons {
  display: flex;
}
.top-section-description {
  color: #fff;
  padding: 0 20px;
  text-align: justify;
  font-size: 15px;
  font-weight: 400;
  line-height: 22.5px;
  max-width: 1140px;
  margin: auto;
}
.stores-redirect-buttons {
  width: 80%;
  margin: auto;
}
.store-button,
.store-button:focus {
  background-color: transparent !important;
  outline-color: transparent !important;
  border-color: transparent !important;
  box-shadow: transparent !important;
  outline: none;
}
.footer-copyrights {
  margin: 30px auto;
  color: #ffffff;
}
.burger-menu-button {
  display: none;
}
.language-switch-button {
  color: #ffffff;
  cursor: pointer;
}
@media only screen and (max-width: 450px) {
  .burger-menu-button {
    display: block;
  }
  .language-switch-button {
    display: none;
  }
  .download-app-button {
    font-size: 12px;
  }
  .oac-logo {
    width: 140px;
    height: 50px;
  }
  .footer-copyrights {
    font-size: 12px;
  }
}
@media only screen and (max-width: 850px) and (min-width: 450px) {
  .oac-logo {
    height: 70px;
  }
  .language-switch-button {
    display: none;
  }
  .burger-menu-button {
    display: block;
  }
}
.show {
  display: block;
}
