.slider-nav-thumbnails .item img,
.slider-nav-thumbnails2 .item img {
  width: 100%;
}
.slider-nav-thumbnails,
.slider-nav-thumbnails2 {
  padding-top: 20px;
}
.slider-nav-thumbnails .item,
.slider-nav-thumbnails2 .item {
  border: 3px solid transparent;
  border-radius: 3px;
  position: relative;
  height: 5.5rem;
}
.slider-nav-thumbnails2 .item {
  border-radius: 10px;
  margin-inline: 5px;
  height: 9rem;
}
.slider-nav-thumbnails2 .item img {
  border-radius: 10px;
}
.slider-nav-thumbnails .item.slick-current,
.slider-nav-thumbnails2 .item.slick-current {
  border-color: #00d6bc;
  border-radius: 14px;
}
.slider-nav-thumbnails .slick-list,
.slider-nav-thumbnails2 .slick-list {
  margin-inline: -5px;
}
.slide-content {
  background-color: #fff;
  border-radius: 10px 0 0 10px;
  overflow: hidden;
  position: relative;
  border: 1px solid #ddd;
  border-right: 0;
  height: 18.7rem;
}
@media (min-width: 1400px) {
  .slider-nav-thumbnails .item img {
    max-width: 82px;
    max-height: 82px;
  }
}
@media (max-width: 1399px) {
  .slider-nav-thumbnails .item img {
    max-width: 78px;
    max-height: 78px;
  }
}
@media (max-width: 991px) {
  .other-models-header {
    margin-left: 10px;
  }
}
@media (max-width: 767px) {
  .slide-content {
    height: 30.7rem;
  }
}
@media screen and (max-width: 595px) {
  .slider-nav-thumbnails .slick-track {
    display: flex !important;
  }
}
@media (max-width: 575px) {
  .deal-page-models-wrapper {
    padding: 0 30px;
  }
}
