.more-review-card-container {
  position: relative;
  margin-bottom: 20px;
  cursor: pointer;
  flex-basis: 45%;
  min-width: 300px;
}
.reviews-details-info p {
  margin-block: 2px;
}
