.errorMessage {
  color: #e7505a;
  padding: 5px 0;
}
.business-inquiry-chkbox {
  float: left;
  margin: 4px;
}
.business-inquiry-txt {
  float: left;
  color: #848484;
}
