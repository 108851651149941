.fab-button-color {
  background-color: #f44336 !important;
}
.fab-button-color:hover {
  background-color: #eb2416 !important;
}
.fab-button-standalone {
  background-color: transparent !important;
  border: unset;
  border-radius: unset;
  box-shadow: none !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
