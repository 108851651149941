.featured-details-page::before {
  position: absolute;
  z-index: -1;
  display: block;
  content: '';
  left: 15px;
  right: 15px;
  top: 0;
  bottom: 0;
  background-color: #fff;
  border-radius: 10px;
  border: 1px solid #eaeaea;
}
.featured-details-page {
  position: relative;
  z-index: 1;
  margin-top: -25px;
  margin-bottom: 50px;
  padding: 35px 30px 50px;
}
.featured-details-page .cars-form-holder {
  background-color: transparent;
  margin: 0 15px 30px;
}
.featured-details-page .car-info-colunm .text-holder {
  border-radius: 0 0 6px 6px;
}
