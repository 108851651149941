.accounts-nav {
  font-size: 16px;
  line-height: 1.4;
  text-transform: capitalize;
  color: #3d3d3d;
  padding-left: 55px;
}
.accounts-nav li {
  border-bottom: 1px solid #ddd;
  padding-block: 6px;
}
.accounts-nav li:last-child {
  border-bottom: 0;
}
.accounts-nav li a {
  color: #3d3d3d;
  display: block;
  position: relative;
  padding: 7px 10px 7px 70px;
  text-overflow: ellipsis;
}
.accounts-nav li a:before {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 100%;
  content: '';
  width: 5px;
  background-color: #00d6bc;
  transition: 0.3s;
}
.accounts-nav li:hover a:before {
  bottom: 0;
}
.accounts-nav .nav-icon {
  position: absolute;
  left: 20px;
  top: 50%;
  max-width: 30px;
  transform: translateY(-50%);
  display: block;
  padding-block: 5px;
}
.accounts-nav .nav-icon img {
  max-width: 30px;
  max-height: 30px;
}
.accounts-nav .nav-icon .count {
  position: absolute;
  top: -3px;
  right: -5px;
  border-radius: 100%;
  width: 14px;
  height: 14px;
  text-align: center;
  line-height: 14px;
  font-size: 9px;
  color: #fff;
  z-index: 99;
  background-color: #00d6bc;
}
.accounts-nav .nav-icon.notifi .count {
  background-color: red;
}
.accounts-aside {
  width: 25%;
  border-right: 6px solid #ebe9e9;
  padding-bottom: 0;
}
.accounts-nav.log {
  padding-left: 10px;
}
.accounts-nav li.pl-0 a {
  padding-left: 20px;
}
.accounts-nav.log li {
  padding-left: 0;
  border-color: #ebe9e9;
  border-width: 10px;
}
.accounts-nav.log li:first-child {
  border-top: 10px solid #ebe9e9;
}
.accounts-nav.log li:last-child {
  border-bottom: 10px solid #ebe9e9;
}
.accounts-nav.log li a {
  margin-left: 50px;
}
.user-login {
  position: relative;
  padding: 25px;
  text-align: center;
  border-bottom: 5px solid #00d6bc;
}
.user-login .username {
  display: block;
  font-weight: 900;
  font-size: 24px;
  line-height: 1.4;
  margin-bottom: 5px;
}
@media (max-width: 1199px) {
  .accounts-nav {
    font-size: 14px;
    padding-left: 35px;
  }
  .accounts-nav.log li a {
    margin-left: 22px;
  }
}
@media screen and (max-width: 991px) {
  .accounts-aside {
    width: 100%;
  }
}
.lang-au .accounts-nav li a:before {
  left: auto;
  right: 0;
}
.lang-au .accounts-nav {
  padding-left: 0;
  padding-right: 55px;
}
.lang-au .accounts-nav.log {
  padding-left: 0;
  padding-right: 10px;
}
.lang-au .accounts-nav li a {
  padding-inline: 70px 10px;
  text-align: right;
}
.lang-au .accounts-nav .nav-icon {
  left: auto;
  right: 0;
}
.lang-au .accounts-nav.log li a {
  margin-left: 0;
  margin-right: 50px;
}
.lang-au .accounts-nav li.pl-0 a {
  padding-left: 0;
  padding-right: 20px;
}
