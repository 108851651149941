.ramadan-details-header {
  color: #fff;
  font-size: 17px;
  line-height: 1.6;
  border-bottom: #00d6bc 2px solid;
  padding-bottom: 20px;
  padding-top: 30px;
}

.ramadan-model-logo {
  width: 100px;
  height: 60px;
  object-fit: contain;
  max-height: 100%;
}

.ramadan-dealer-logo {
  width: 300px;
  height: 60px;
  object-fit: contain;
}

@media (max-width: 600px) {
  .ramadan-dealer-logo {
    width: 230px;
    object-fit: contain;
  }

  .ramadan-model-logo {
    width: 80px;
    object-fit: contain;
  }
}
