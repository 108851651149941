.chat-users-nav li.active,
.chat-users-nav li:hover {
  background-color: #ebebeb;
}
.chat-users-nav {
  width: 36%;
  border-right: 6px solid #ebe9e9;
}
.chat-users-nav.customscroll {
  height: calc(100vh);
  overflow-x: hidden;
  overflow-y: auto;
}
.chat-users-nav li {
  border-bottom: 1px solid #dedede;
  width: 100%;
  display: inline-block;
  padding: 25px 30px;
  cursor: pointer;
  transition: 0.3s;
  position: relative;
}
.chat-users-nav li.active .user-chat-state {
  background-color: #00e600;
}
.chat-users-nav .mCSB_outside + .mCS-minimal-dark.mCSB_scrollTools_vertical,
.chat-users-nav .mCSB_outside + .mCS-minimal.mCSB_scrollTools_vertical {
  right: -5px !important;
}
@media (max-width: 595px) {
  .chat-users-nav.customscroll {
    height: auto;
  }
  .chat-users-nav {
    width: 100%;
    border-right: 6px solid #ebe9e9;
  }
}
