.MuiAutocomplete-popupIndicator {
  display: none !important;
}
.float-left {
  float: left;
}
.search-wrapper {
  position: relative;
  width: 52%;
  position: absolute;
  top: 17px;
}
.search-info-bar {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin-bottom: 4px;
  gap: 24px;
  align-items: center;
}
input[name='car-condition'] {
  display: none;
}
.new-toggle {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}
.old-toggle {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}
#new-checkbox:checked ~ .new-toggle {
  background: #00d9bb;
  color: white;
}
#old-checkbox:checked ~ .old-toggle {
  background: #00d9bb;
  color: white;
}
.toggle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 100%;
}
.toggles-wrapper {
  display: flex;
  border-radius: 4px;
  border: 1px solid #00d9bb;
  width: 150px;
  height: 25px;
  align-items: center;
}
.header-separator {
  width: 100%;
  height: 0;
  background-color: #e5e4e5;
}
.old-new-toggle-wrapper {
  position: absolute;
  top: 7px;
  border: none;
}
:not(.lang-au) .old-new-toggle-wrapper {
  right: 5px;
}
.search-wrapper > div > div > div {
  background-color: #f6f6f6;
}
.search-wrapper > div > div > div:hover {
  background-color: #fff;
}
.search-wrapper > div > div > div > fieldset {
  border-color: #d1d1d1 !important;
  border-width: 1px !important;
}
#header .register-info {
  top: 17px;
  right: -18.4%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: absolute;
}
#header .register-info .txt-feedback {
  display: block;
  font-size: 15px;
  line-height: 17px;
  font-weight: 400;
  color: #5b5b5b;
}
#header .register-info .txt-feedback:hover {
  color: #00d6bc;
}
#header .register-info .btn-register {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 118px;
  height: 24px;
  font-size: 15px;
  line-height: 17px;
  font-weight: 400;
  color: #5b5b5b;
  margin: 0 0 0 39px;
  border-radius: 3px;
  border: 1px solid #595959;
}
#header .register-info .btn-register:hover {
  background: #595959;
  color: #fff;
}
#header .nav-holder {
  position: relative;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  padding: 0;
  transition: 0.3s;
}
#header .fake-select:after,
#header .fake-select:before {
  display: none;
}
#header {
  padding: 0;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background: #fff;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 1000;
  transition: 0.3s;
}
#header .nav-info-bar:after,
#header:after,
#header .header-holder {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0 20px;
  transition: 0.3s;
}
#header .logo {
  display: block;
  max-width: 150px;
  transition: 0.3s;
}
#header .logo img,
.btn-list li a img {
  display: block;
  height: auto;
  width: 100%;
}
#header .search-info-bar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  z-index: 9;
  transition: 0.3s;
}
#header .nav-info-bar {
  padding: 23px 0 14px;
}
#header .form-opener {
  right: 55px;
  bottom: 8px;
  position: absolute;
  padding: 0;
  width: 40px;
  height: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: #fff;
  border: 1px solid #000;
  border-radius: 0.25rem;
}
#header .btn-shop {
  display: -webkit-box;
  display: -ms-flexbox;
  -webkit-box-align: center;
  width: 140px;
  height: 33px;
  border-radius: 3px;
  background: #ebebeb;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: absolute;
  top: 100%;
  right: 0;
  margin-top: 18px;
}
#header .form-opener .fa {
  font-size: 20px;
  color: #000;
}
#header .nav-column {
  display: -webkit-box;
  display: -ms-flexbox;
  -webkit-box-align: center;
  width: 100%;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
#header .btn-shop:hover {
  opacity: 0.7;
}
#header .btn-shop .ico {
  display: block;
  margin: 0 10px 0 0;
}
#header .btn-shop .ico img {
  display: block;
  height: auto;
  max-width: 100%;
}
#header .btn-shop .txt {
  display: block;
  font-size: 16px;
  line-height: 18px;
  color: #848484;
  font-weight: 700;
  text-transform: uppercase;
}
#header .select {
  font-size: 13px;
  line-height: 16px;
  font-weight: 400;
  color: #848484;
  border: none;
  padding: 0;
  width: auto;
  height: auto;
  display: inline-block;
  border-radius: 0;
  background: 0 0;
  cursor: pointer;
}
.menu-dopdown {
  position: absolute;
  top: 150%;
  left: 0;
  width: 150px;
  visibility: hidden;
  opacity: 0;
  z-index: 99;
  padding-top: 14px;
  transition: all 0.3s ease;
}
.navbar-nav .nav-item:hover > .menu-dopdown {
  top: 100%;
  visibility: visible;
  opacity: 1;
}
.dopdown-nav {
  background-color: #fff;
  box-shadow: 0 0 8px rgb(0 0 0 / 20%);
  padding: 5px;
  font-size: 13px;
  line-height: 1.4;
  text-transform: uppercase;
}
.navbar-nav .dopdown-nav > li {
  display: block;
  margin: 0;
}
.navbar-nav .nav-item .dopdown-nav > li > a {
  display: block;
  border-top: 1px solid #d7d7d7;
  color: #848484;
  transition: all 0.3s ease;
  padding: 10px;
}
.navbar-nav .nav-item .dopdown-nav > li:last-child > a {
  border-bottom: 1px solid #d7d7d7;
}
.navbar-nav .nav-item .dopdown-nav > li.active > a,
.navbar-nav .nav-item .dopdown-nav > li.show > a,
.navbar-nav .nav-item .dopdown-nav > li:focus > a,
.navbar-nav .nav-item .dopdown-nav > li:hover > a {
  color: #1d2327 !important;
  background-color: #ebebeb;
}
.container-fluid {
  padding-right: 0 !important;
  padding-left: 0 !important;
  margin-right: 0 !important;
  margin-left: 0 !important;
}
.nav-brand-placeholder {
  margin: 0 !important;
}
span.arabic.pl-3 {
  min-width: 90px;
}
.search-info-bar {
  padding-top: 4px;
  font-size: 12px;
}
.login-button {
  display: inline-block;
  vertical-align: middle;
}
.login-button {
  color: #162021;
  font-weight: 700;
  max-width: 160px;
}
.country-header {
  width: 35px;
  cursor: pointer;
}
.modal-dropdown {
  position: absolute;
  display: flex;
  width: 270px;
  margin-top: 30px;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  border-radius: var(--Number, 0px) var(--Number, 0px) 24px 24px;
  border: 1px solid rgba(193, 193, 193, 0.8);
  background: #fff;
}
.modal-dropdown-text {
  color: #1c2628;
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
}
.modal-dropdown-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
}
.modal-dropdown-item {
  display: flex;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid #707070;
  cursor: pointer;
}
.modal-dropdown-item.selected {
  background: #e4e4e4;
}
.modal-dropdown-item-text {
  color: #1c2628;
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
}
.modal-dropdown-item-flag {
  width: 22.667px;
  height: 16px;
  margin: unset;
}
.lang-button {
  max-width: 110px;
  min-width: 110px;
  background: 0 0;
  border: 0;
  font-weight: 700;
  cursor: default !important;
  padding-top: 2px;
  font-size: 14px;
  min-height: 19px;
  line-height: 19px;
  box-sizing: border-box;
  margin-inline: 10px;
  color: #162021;
}
.lang-button img {
  position: absolute;
  left: 0;
  top: 0;
}
.lang-header:hover {
  cursor: pointer;
  color: #00d6bc;
}
.button-as-link {
  border: none !important;
  background: none !important;
  outline: none !important;
}
@media (min-width: 1200px) {
  .sticky-header #header .nav-holder:before,
  .sticky-header #header .search-info-bar,
  .sticky-header #header .search-wrapper,
  .sticky-header .search-form {
    opacity: 0;
    visibility: hidden;
  }
  #header .header-holder {
    padding-left: 24%;
  }
  #header .logo {
    position: absolute;
    left: 20px;
    top: 50%;
    max-width: 194px;
    transform: translateY(-50%);
  }
  #header .search-info-bar {
    position: absolute;
    right: 0;
    top: 17px;
  }
  #header .nav-holder {
    padding-top: 56px;
  }
  #header .sticky-header .nav-holder,
  .sticky-header #header .nav-holder {
    padding-top: 5px;
    padding-bottom: 10px;
  }
  .sticky-header #header .logo {
    max-width: 130px;
  }
  #header .nav-holder:before {
    top: 56px;
    left: 0;
    right: -9999px;
    height: 1px;
    content: '';
    position: absolute;
    background: #e5e4e5;
    transition: 0.3s;
  }
  #header .form-opener {
    display: none;
  }
}
@media (min-width: 1475px) {
  #header .container {
    max-width: 1465px;
  }
}
@media (max-width: 1899px) {
  #header .register-info {
    right: -14.4%;
  }
}
@media (max-width: 1599px) {
  #header .search-info-bar {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  #header .search-info-bar:after {
    display: none;
  }
  #header .register-info {
    right: 0;
  }
}
@media (max-width: 1460px) {
  #header .container {
    max-width: 100%;
    padding-inline: 0;
  }
}
@media (max-width: 1199px) {
  .sticky-header .header-holder {
    margin-top: -53px;
  }
  .nav-holder {
    z-index: 1;
  }
  .login-button {
    float: right;
    margin-top: 0;
  }
  .search-wrapper {
    top: 59.5px;
    right: 112px;
  }
  .lang-au .search-wrapper {
    right: 30px;
  }
  #header .register-info .btn-register {
    margin-left: 25px;
  }
  #header .left-align {
    position: static;
    width: auto;
  }
  #header .btn-shop {
    margin: -5px 0 0 20px;
    position: static;
    float: right;
  }
  #header .register-info {
    position: static;
  }
  #header .container {
    position: relative;
  }
  #header .header-holder {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    padding: 10px 2% 15px;
  }
  #header .search-info-bar {
    min-height: inherit;
    padding-bottom: 10px;
    display: block;
    border-bottom: 1px solid #ddd;
  }
  #header .logo {
    left: 15px;
    bottom: 20px;
    max-width: 140px;
    margin: 0;
    position: absolute;
  }
  .lang-au #header .logo {
    left: unset;
    right: 15px;
    bottom: 18px;
  }
  #header .nav-holder {
    display: block;
    padding: 0 0 8px;
  }
  #header .nav-info-bar {
    padding: 15px 0 0;
  }
  #header .nav-column {
    padding: 15px;
    display: block;
  }
}
@media (max-width: 767px) {
  #header .register-info .btn-register {
    margin: 0 0 0 5px;
  }
  #header .register-info .btn-register {
    margin: 0 0 0 5px;
  }
}
@media (max-width: 595px) {
  .search-wrapper {
    width: 67% !important;
  }
}
@media (max-width: 575px) {
  .old-new-toggle-wrapper {
    top: 40px !important;
  }
  :not(.lang-au) .old-new-toggle-wrapper {
    right: 0 !important;
  }
  .lang-au .old-new-toggle-wrapper {
    left: 0 !important;
  }
  #header .logo {
    max-width: 120px;
    left: 15px;
    bottom: 22px;
  }
  .lang-au #header .logo {
    left: unset;
    right: 15px;
    bottom: 22px;
  }
  #header .btn-shop {
    width: 85px;
    margin: 0 5px;
  }
  #header .btn-shop .ico {
    margin: 0 5px 0 0;
  }
  #header .btn-shop .txt {
    font-size: 14px;
  }
  #header .register-info .txt-feedback {
    font-size: 13px;
  }
  #header .register-info .btn-register {
    width: 98px;
    font-size: 13px;
  }
}
