.accounts-language {
  display: flex;
  padding: 24px 16px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border: 1px solid rgba(204, 204, 204, 0.8);
  background: rgba(255, 255, 255, 0.8);
}
.accounts-language-title {
  color: #4f4f4f;
  font-family: Lato;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 13.5px;
}
.accounts-language-list {
  display: flex;
  padding: 8px;
  align-items: center;
  gap: 16px;
  border-radius: 8px;
  border: var(--Number, 1px) solid #e1e1e1;
}
.accounts-language-item {
  display: flex;
  height: 40px;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
}
.accounts-language-item-title {
  color: #4f4f4f;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 13.5px;
  cursor: pointer;
  width: 100%;
}
.accounts-language-item-title:hover {
  color: #00d6bc;
}
.accounts-language-item-2 {
  display: flex;
  width: 99px;
  height: 40px;
  padding: 16px;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  border: 1px solid #707070;
  background: #e4e4e4;
  text-align: center;
}
.accounts-country-item {
  display: flex;
  height: 40px;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  cursor: pointer;
}
.accounts-country-item.selected {
  display: flex;
  height: 40px;
  padding: 16px;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  border-radius: 8px;
  border: 1px solid #707070;
  background: #e4e4e4;
}
.modal-dropdown-item-text:hover {
  color: #00d6bc;
}
@media screen and (max-width: 768px) {
  .accounts-language {
    flex-direction: column;
    padding: 16px;
    gap: 16px;
  }
  .accounts-language-title {
    font-size: 16px;
  }
  .accounts-language-item-title {
    font-size: 14px;
  }
  .accounts-language-item-2 {
    width: 80px;
    height: 40px;
    padding: 16px;
  }
  .accounts-country-item {
    padding: 12px 16px;
  }
  .accounts-country-item.selected {
    padding: 16px;
  }
}
