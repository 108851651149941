.banner-carousel > ul.react-multi-carousel-track {
  z-index: 0;
  height: 100%;
}
.banner-carousel
  > ul.react-multi-carousel-track
  > li.react-multi-carousel-item:not(.react-multi-carousel-item--active) {
  visibility: hidden;
}
.carousel-indicators .txt {
  text-transform: uppercase;
}
.bannerslide-item,
div.video-box > div.react-multi-carousel-list,
div.video-box > div.react-multi-carousel-list > ul.react-multi-carousel-track {
  height: 100%;
  background-color: #f6f6f6;
}
.react-multi-carousel-list {
  height: 100%;
  position: unset !important;
  overflow: hidden;
  display: flex;
  align-items: center;
}
.react-multi-carousel-track {
  display: flex;
  flex-direction: row;
  backface-visibility: hidden;
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
  transform-style: preserve-3d;
  will-change: transform, transition;
}
.carousel-indicators {
  list-style: none;
}
.carousel-indicators {
  padding: 0 !important;
  margin: 0 -8px !important;
  bottom: 13px !important;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: center;
}
.arabic-carousel-indicators {
  direction: rtl;
}

.carousel-indicators li.active .border {
  background: #00d6bc;
}
.carousel-indicators .text-hold {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 0 0 7px;
}
.carousel-indicators .text-hold .ico {
  margin: 0 10px 0 0;
  display: block;
}
.carousel-indicators .text-hold .ico img {
  display: block;
  height: height;
  max-width: 100%;
}
.carousel-indicators .txt {
  display: block;
  font-size: 15px;
  line-height: 18px;
  font-weight: 400;
  color: #fff;
}
.carousel-indicators .border {
  display: block;
  width: 152px;
  height: 8px;
  border: none !important;
  border-radius: 15px;
  background: #fff;
}
@media (max-width: 991px) {
  .carousel-indicators {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
}
@media (max-width: 767px) {
  .carousel-indicators .text-hold .ico {
    display: none;
  }
  .carousel-indicators .border,
  .react-tel-input .form-control {
    width: 100%;
    margin-bottom: 20px;
  }
  .carousel-indicators .txt {
    font-size: 13px;
    line-height: 16px;
  }
  .lang-au .carousel-indicators .txt {
    font-size: 17px;
    line-height: 16px;
  }
}
