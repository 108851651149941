.search-details .deals-left {
  width: 53%;
  align-items: center;
  padding: 15px 20px;
}
.search-details .deals-advantages {
  width: 45%;
  margin-top: 13px;
}
.search-details .deals-nav li {
  margin-bottom: 10px;
  display: block;
  width: 100%;
  padding-inline: 0;
  color: #b3b3b3;
}
.search-details .deals-right {
  width: 47%;
  min-height: inherit;
  padding-top: 15px;
  align-items: center;
}
.search-details .deals-right .frame {
  padding-top: 0;
  justify-content: center;
}
.usedcars-rows .deals-advantages .small-logos {
  top: 15px;
  right: 0;
  max-width: 65px;
}
.usedcars-rows .deals-advantages .deal-title {
  padding-right: 70px;
}
.GridBanner:nth-child(5) {
  width: 100%;
  margin: 0 auto;
  display: inline-block;
  vertical-align: top;
}
@media (max-width: 1199px) {
  .search-details .deals-right .box {
    width: 65%;
    padding-right: 15px;
  }
  .search-details .deals-right .frame {
    width: 35%;
  }
  .search-details .deals-advantages {
    padding: 0 0 10px;
  }
}
@media (max-width: 991px) {
  .search-details .deals-right .frame {
    width: 100%;
  }
  .search-details .deals-right .box {
    width: 100%;
    padding-right: 0;
  }
  .search-details .deals-advantages {
    padding: 10px 0 0;
    text-align: left;
    max-width: 245px;
    margin: 0 auto;
  }
}
@media (max-width: 767px) {
  .search-details .deals-left {
    width: 100%;
    padding: 15px 20px;
  }
  .search-details .deals-right .box {
    width: 60%;
  }
  .search-details .deals-right .frame {
    width: 40%;
  }
  .lang-au .search-details .deals-right .frame {
    text-align: center;
    width: 40%;
    padding-top: 0;
    position: relative;
    z-index: 1;
    height: 80%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
  }
  .search-details .deals-right {
    width: 100%;
    align-items: flex-start;
  }
}
@media (max-width: 479px) {
  .CarListGridImage {
    height: 232px !important;
  }
}
