.signup-col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.signup-holder {
  border: 1px solid #eaeaea;
  background-color: #fff;
  border-radius: 10px;
  padding: 4%;
  margin: 50px auto;
  max-width: 1125px;
}
.signup-box {
  max-width: 635px;
  margin: 0 auto;
}
.signup-box .resend-txt {
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
}
.signup-box .resend-txt a {
  color: #cbcbcb;
  text-decoration: underline;
}
.signup-box .resend-txt a:hover {
  color: #00d6bc;
}
.signup-box h2 {
  font-size: 25px;
  line-height: 1.5;
  color: #3d3d3d;
  margin: 0 0 30px;
  font-weight: 700;
  text-align: center;
}
.signup-box .login-logo {
  margin-bottom: 15px;
}
.signup-box .btn-primary {
  max-width: 300px;
}
.signup-box .form-group {
  margin-bottom: 20px;
}
.signup-box .form-text p {
  max-width: 410px;
  margin: 20px auto;
  text-align: center;
  font-size: 20px;
  line-height: 1.4;
}
.signup-box .button {
  padding-top: 50px;
}
.authentication .signup-box .button {
  padding-top: 20px;
}
.login-logo {
  margin-bottom: 15px;
}
.login-logo img {
  max-width: 105px;
}
.already-login {
  text-align: center;
  padding-top: 5px;
}
.already-login p a {
  color: #919191;
  font-weight: 700;
}
.already-login p a:active,
.already-login p a:focus,
.already-login p a:hover {
  color: #04e4cc;
}
@media screen and (max-width: 991px) {
  .login-logo {
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 767px) {
  .signup-holder {
    padding: 30px 20px;
    margin: 30px auto;
  }
  .signup-box .button {
    padding-top: 10px;
  }
}
