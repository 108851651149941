.reviews-filters-bar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  height: 80px;
}
@media screen and (max-width: 1199px) {
  .reviews-filters-bar-container {
    padding-top: 15px;
  }
}
@media screen and (max-width: 540px) {
  .reviews-filters-bar-container {
    gap: 10px;
  }
}
@media screen and (max-width: 430px) {
  .reviews-filters-bar-container {
    gap: 5px;
  }
}
@media screen and (max-width: 360px) {
  .reviews-filters-bar-container {
    flex-direction: column;
    height: 220px;
  }
}
