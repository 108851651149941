.lang-au .sell_your_car_data {
  text-align: right;
}
.lang-au .sell-car-first-page-box-content {
  height: 100px;
}
.lang-au .sell-car-first-page-box-content h2 {
  font-size: 27px;
}
.lang-au .vin-err-msg {
  bottom: 18%;
}
.sell_your_car_data {
  height: auto;
  background-color: rgba(255, 255, 255, 0.274);
  display: flex;
  flex-direction: column;
  gap: 50px;
}
.sell-car-first-page-box {
  display: flex;
  flex-direction: column;
  gap: 24px;
  border-radius: 16px;
  padding: 64px 94px 64px 94px;
  background-color: white;
  box-shadow: 1px 1px 2px 0px #00000014;
  width: 1089px;
}
.sell-car-first-page-box-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 26px;
  width: 901px;
}
.sell-car-first-page-box-content {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 561px;
  height: 77px;
}
.sell-car-first-page-box-content h2 {
  font-size: 30px;
  font-weight: 500;
  line-height: 28px;
}
.sell-car-first-page-box-content strong {
  font-size: 32px;
  font-weight: 900;
  line-height: 28px;
}
.sell-car-first-page-box-content p {
  font-size: 15px;
  font-weight: 400;
  line-height: 19.2px;
  opacity: 80%;
}
.sell-your-car-vin-search-bar-form {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 901px;
}
.sell-your-car-vin-search-bar {
  display: flex;
  height: 55px;
  border-radius: 12px;
  border: 0.75px solid #e2e8f0;
  background: #f3f9fc;
  padding: 6px 18px 6px 18px;
  width: 715px;
  cursor: text;
}
.sell-your-car-vin-search-bar:focus {
  outline: none;
}
.sell-your-car-vin-search-bar-container {
  display: flex;
  gap: 24px;
  width: 100%;
}
.sell-your-car-vin-search-bar span {
  font-size: 14px;
  font-weight: 400;
  line-height: 28px;
  color: #1c2628;
}
.lang-au .scan-icon {
  position: relative;
  right: -10%;
  top: 25%;
  width: 32px;
  height: 32px;
  cursor: pointer;
}
.scan-icon {
  position: relative;
  right: 8%;
  top: 25%;
  width: 32px;
  height: 32px;
  cursor: pointer;
}
.sell-your-car-vin-search-button {
  width: 162px;
  height: 64px;
  padding: 6px 18px 6px 18px;
  color: white;
}
.sell-your-car-vin-search-button:focus {
  outline: none;
}
.sell-car-first-page-box-container h3 {
  height: 10px;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  width: 202px;
  color: #0189d5;
}
.sell-car-first-page-box-container h3:hover {
  text-decoration: underline;
  cursor: pointer;
}
.vin-err-msg {
  color: red;
  font-size: small;
  position: absolute;
  bottom: 26%;
}

@media (max-width: 1200px) {
  .sell_your_car_data {
    gap: 20px;
  }
  .sell-car-first-page-box {
    padding: 64px 24px 64px 24px;
    width: 100%;
    height: 100%;
  }
  .sell-car-first-page-box-container {
    gap: 20px;
    width: 100%;
  }
  .sell-car-first-page-box-content {
    gap: 10px;
    width: 100%;
    height: 100%;
  }
  .sell-car-first-page-box-content h2 {
    font-size: 24px;
  }
  .sell-car-first-page-box-content strong {
    font-size: 24px;
  }
  .sell-car-first-page-box-content p {
    font-size: 14px;
    width: 100%;
  }
  .sell-your-car-vin-search-bar {
    width: 100%;
  }
  .sell-your-car-vin-search-button {
    width: 100px;
  }
  .vin-err-msg {
    bottom: 29%;
  }
  .lang-au .sell-car-first-page-box {
    padding: 64px 24px 64px 24px;
    width: 100%;
  }
  .lang-au .scan-icon {
    position: absolute;
    right: 90%;
    top: 7%;
    width: 32px;
    height: 32px;
    cursor: pointer;
  }
  .scan-icon {
    position: absolute;
    right: 4%;
    top: 7%;
    width: 32px;
    height: 32px;
    cursor: pointer;
  }
}
@media (max-width: 590px) {
  .vin-err-msg {
    bottom: 23%;
  }
}
@media (max-width: 519px) {
  .vin-err-msg {
    bottom: 16%;
    font-size: x-small;
  }
  .sell-car-first-page-box-content {
    height: 105px;
  }
}
@media (max-width: 405px) {
  .lang-au .sell-car-first-page-box-content {
    height: 115px;
  }
  .lang-au .sell-car-first-page-box-content h2 {
    font-size: 22px;
  }
  .lang-au .sell-car-first-page-box-content strong {
    font-size: 24px;
  }
  .lang-au .sell-car-first-page-box-content p {
    font-size: 12px;
  }
  .lang-au .vin-err-msg {
    bottom: 15%;
  }
  .lang-au .scan-icon {
    right: 85%;
  }
}
