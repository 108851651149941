.ramadan-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
  margin: 0 auto;
  max-width: 1125px;
}

.ramadan-offer-details {
  gap: 20px;
  width: 100%;
}

@media screen and (max-width: 992px) {
  .ramadan-offer-details {
    flex-wrap: wrap;
  }
}
