.advance-search-modal.loginpopup .modal-lg {
  max-width: 396px;
}
.advance-search-modal.loginpopup .btn-primary {
  border-radius: 10px;
  padding-bottom: 9px;
}
.advance-search-modal.loginpopup .modal-header .close {
  right: 7px;
  top: -4px;
}
.advance-search-modal .modal-lg {
  max-width: 1200px;
}
.advance-search-modal .modal-content {
  border-radius: 10px;
  padding: 2%;
  max-height: 90vh;
  overflow-y: auto;
  overflow-x: hidden;
}
.advance-search-modal .modal-header {
  position: static;
  border: 0;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  flex-direction: column;
  padding-block: 0;
}
.advance-search-modal .modal-header .close {
  position: absolute;
  right: 15px;
  top: 5px;
  font-size: 36px;
  font-weight: 400;
  opacity: 1;
  color: #3d3d3d;
  outline: 0;
  transition: 0.3s;
}
.advance-search-modal .modal-header .close:active,
.advance-search-modal .modal-header .close:focus,
.advance-search-modal .modal-header .close:hover {
  color: #04e4cc;
  outline: 0;
}
.advance-search-modal .modal-body {
  padding-block: 0;
}
.advance-search-modal.loginpopup .modal-header {
  position: relative;
  z-index: 9;
}
.advance-search-modal button[type='reset']:hover {
  color: #fff;
  background-color: #04e4cc;
  border-color: #04e4cc;
}
.advance-search-modal .modal-title {
  font-size: 22px;
  font-weight: 600;
  text-align: center;
}
.advance-search-modal .modal-footer {
  border-top: 0;
  margin-top: -20px;
  padding-block: 0;
}
.advance-search-modal button[type='reset'] {
  background-color: transparent;
  border-color: #3d3d3d;
  color: #3d3d3d;
  margin-right: 15px;
  font-weight: 600;
}
.advance-search-modal .btn-primary {
  min-width: 120px;
  border-radius: 50px;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 1px;
  height: auto;
  padding-top: 10px;
  padding-bottom: 12px;
  transition: 0.3s;
}
.advance-search-modal .btn-primary.btn-clear {
  background: 0 0;
  color: #3e3e3e;
  border-color: #d1d1d1;
}
.advance-search-modal .form-group {
  margin-bottom: 0.75rem;
}
.login-signup {
  text-align: center;
  margin-block: 20px;
}
.login-signup .login-img {
  max-width: 105px;
  margin: 20px auto 30px;
}
.login-signup .login-img img {
  max-width: 150px;
}
.advance-search-modal .hr-lines {
  margin-block: 15px;
  border-color: #c2c2c2;
}
@media (max-width: 1399px) {
  .advance-search-modal .modal-lg {
    max-width: 1100px;
  }
}
@media (max-width: 1199px) {
  .advance-search-modal .modal-lg {
    max-width: 900px;
  }
}
