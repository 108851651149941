.browse-offers-section {
  background-color: #fff;
  padding-top: 15px;
  padding-bottom: 15px;
  margin-bottom: 50px;
}

.browse-offers {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
}

.ramadan-list-offers {
  padding-top: 32px;
}

.ramadan-slider-section-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding-bottom: 10px;
  border-radius: 15px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
}

@media screen and (max-width: 1024px) {
  .browse-offers {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 545px) {
  .browse-offers {
    grid-template-columns: repeat(1, 1fr);
  }
}
