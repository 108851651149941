.ramadan-offers-silder-carousel {
  position: relative !important;
  width: 530px;
  background-color: #000;
}

.ramadan-slider-section-carousel {
  width: 100%;
  height: 349px;
  object-fit: contain;
}

@media screen and (max-width: 992px) {
  .ramadan-offers-silder-carousel {
    width: 100%;
  }

  .ramadan-slider-section-carousel {
    width: 100%;
    height: 400px;
  }
}
