.btn-list li a,
.sharenav li:hover .reaction-blocks,
.slick-slide img,
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
img,
menu,
nav,
section {
  display: block;
}
#a li,
.ellipsis,
.lang-button span,
.login-button {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.lang-button,
.lang-select,
.table tr td {
  vertical-align: middle;
}
#a li,
.ellipsis,
.wrapper {
  overflow: hidden;
}
.navbar-light .navbar-nav > .nav-item .nav-link:focus,
.navbar-light .navbar-nav > .nav-item .nav-link:hover,
.navbar-nav .nav-link:hover,
.send-msg.active svg,
a:hover {
  color: #00d6bc;
}
#a li,
.container,
.lang-button,
.lang-select,
.search-info-bar,
.wrapper {
  position: relative;
}
.btn-secondary,
.cars-selection-form label,
.form-title h2,
.lang-button,
.navbar-nav,
.title-header h1 {
  text-transform: uppercase;
}
#a li,
.social-icons,
ol,
ul {
  list-style: none;
}
a,
abbr,
acronym,
address,
applet,
article,
aside,
audio,
b,
big,
blockquote,
body,
canvas,
caption,
center,
cite,
code,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
embed,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
mark,
menu,
nav,
object,
ol,
output,
p,
pre,
q,
ruby,
s,
samp,
section,
small,
span,
strike,
strong,
sub,
summary,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
tt,
u,
ul,
var,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  font-family: Lato;
  vertical-align: baseline;
  scroll-behavior: smooth;
}
strong {
  font-weight: 700;
}
em {
  font-style: italic;
}
blockquote,
q {
  quotes: none;
}
blockquote:after,
blockquote:before,
q:after,
q:before {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
img {
  max-width: 100%;
  height: auto;
  margin: 0 auto;
}
#a li img,
.b,
.lang-au .lang-button .english,
.lang-button .arabic,
.vodiapicker {
  display: none;
}
html {
  scroll-behavior: smooth;
}
body {
  color: #000;
  min-width: 320px;
  font-family: Lato, sans-serif;
  background: #f8f8f8;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
.resize-active * {
  transition: none !important;
  -webkit-transition: none !important;
}
a {
  -webkit-transition: background 0.3s linear, color 0.3s linear,
    opacity 0.3s linear, border 0.3s linear, button 0.3s linear;
  transition: background 0.3s linear, color 0.3s linear, opacity 0.3s linear,
    border 0.3s linear, button 0.3s linear;
}
a:hover {
  text-decoration: none;
  outline: 0;
}
#a,
#a img,
.btn-select img {
  width: 32px;
}
.b,
.lang-button,
.lang-button span,
.wrapper {
  width: 100%;
}
#a li {
  padding-top: 7px;
  padding-bottom: 7px;
}
#a li img {
  margin: 0;
  position: absolute;
  left: 0;
  width: 18px;
}
#a li span,
.btn-select li span {
  margin-left: 0;
  cursor: pointer;
}
.b {
  max-width: 100px;
}
.btn,
.lang-au .lang-button .arabic,
.lang-button,
.lang-button .english,
.lang-select {
  display: inline-block;
}
.open {
  display: show !important;
}
.wrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  transition: 0.3s;
  padding-top: 110px;
}
.btn {
  vertical-align: top;
  border-radius: 0;
  white-space: normal;
}
.loginpopupload {
  white-space: nowrap;
}
.modal {
  display: block !important;
}
.hide {
  display: none !important;
}
#mainHome {
  min-height: 100vh;
}
.custom-tel {
  background-color: #f6f6f6 !important;
}
:not(.lang-au) .custom-tel {
  padding-left: 45px !important;
}
.lang-au .custom-tel {
  padding-right: 50px !important;
}
.modal > .modal-dialog-signup {
  top: 25%;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
  appearance: textfield;
}
body:has(div.modal.show.deals-form-modal) {
  overflow: hidden;
}
.pagination-middle ul {
  justify-content: center;
}
.plyr {
  height: 100% !important;
}
.react-tel-input .form-control {
  width: 100% !important;
  font-size: 14px !important;
  background-color: #fff !important;
  border-radius: 0;
  height: 39px;
}
.lang-au .react-tel-input .form-control {
  text-align: right;
  padding-right: 50px;
}
input[type='radio'] {
  position: absolute;
  opacity: 0;
  z-index: -1;
}
.btn-primary {
  vertical-align: top;
}
.btn-primary.disabled,
.btn-primary:disabled {
  color: #fff !important;
  background-color: #c1c1c1 !important;
  border-color: #c1c1c1 !important;
}
.btn-primary:hover {
  color: #fff !important;
  background-color: #c1c1c1 !important;
  border-color: #c1c1c1 !important;
}
.bg-light {
  background: 0 0 !important;
}
.navbar-expand-xl .navbar-nav .nav-link {
  padding: 0;
}
.navbar {
  padding: 0;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  position: inherit;
}
.navbar-nav {
  font-size: 16px;
  line-height: 18px;
  font-weight: 400;
}
.navbar-nav .nav-item {
  position: relative;
  margin: 0 29px 0 0;
}
.navbar-nav .nav-link {
  color: #848484;
}
.navbar-light .navbar-nav .nav-link {
  color: #848484;
  position: relative;
}
.navbar-light .navbar-nav > .nav-item > .nav-link:before {
  position: absolute;
  left: 50%;
  right: 50%;
  top: 100%;
  content: '';
  height: 6px;
  background-color: #00d6bc;
  transition: 0.3s;
  margin-top: 3px;
}
.navbar-light .navbar-nav > .nav-item.active > .nav-link,
.navbar-light .navbar-nav > .nav-item.focus > .nav-link,
.navbar-light .navbar-nav > .nav-item.show > .nav-link {
  color: #848484;
  font-weight: 700;
}
.navbar-light .navbar-nav > .nav-item.active > .nav-link:before,
.navbar-light .navbar-nav > .nav-item.focus > .nav-link:before,
.navbar-light .navbar-nav > .nav-item.show > .nav-link:before {
  left: 0;
  right: 0;
}
.navbar-light .navbar-toggler {
  padding: 0;
  width: 40px;
  height: 40px;
  border-color: #000;
  background: #fff;
}
.navbar-light .navbar-toggler-icon {
  background-size: 26px;
  background-position: center;
  width: 100%;
}
.home-banner,
iframe {
  width: 100%;
  height: 100%;
}
.carousel {
  height: 100%;
  position: inherit;
}

.carousel-box {
  aspect-ratio: 16/9;
  border: 4px solid #fff;
}
.carousel-box:has(.react-player__preview) {
  width: 100%;
  border: 4px solid #fff;
}
.carousel-box:has(img) {
  width: 100%;
  border: 4px solid #fff;
}
#main {
  position: relative;
  z-index: 9;
}
#main:after {
  display: block;
  content: '';
  clear: both;
}
.select::-ms-expand {
  display: none;
}
.select {
  padding: 4px 43px 4px 15px;
  margin: 0;
  line-height: 20px;
  border: none;
  -webkit-appearance: none;
  appearance: none;
}
.cars-selection-form .input-group.date .form-control {
  box-shadow: none !important;
}
.searchcar .cars-selection-form .form-group.buttons {
  margin-bottom: 0;
}
.form-control {
  height: 40px;
  padding: 7px 15px;
  font-size: 16px;
  line-height: 22px;
  color: #000;
  background-color: #f6f6f6;
  border-color: #d1d1d1;
  border-radius: 0;
}
.slider-holder {
  width: 37%;
  position: relative;
}
.slider-holder.used-detail .slide-content {
  border-right: 1px solid #ddd;
  margin-right: 1px;
  border-radius: 10px;
  height: 35rem;
}
.btn-secondary {
  background-color: #00d6bc;
  border: 1px solid #00d6bc;
  min-width: 160px;
  text-align: center;
  color: #fff;
  outline: 0;
  letter-spacing: 1px;
}
.btn-secondary.focus,
.btn-secondary:focus,
.btn-secondary:hover,
.btn-secondary:not(:disabled):not(.disabled).active,
.btn-secondary:not(:disabled):not(.disabled):active,
.show > .btn-secondary.dropdown-toggle {
  background: #04e4cc;
  border-color: #04e4cc;
  box-shadow: none;
  outline: 0;
}
.btn-secondary.traffic {
  font-size: 0.91em;
}
.btn-primary {
  display: inline-block;
  min-width: 150px;
  height: 41px;
  font-size: 16px;
  line-height: 18px;
  font-weight: 700;
  color: #fff;
  border-radius: 3px;
  background: #00d6bc;
  border: 1px solid #00d6bc;
}
.btn-primary:active,
.btn-primary:focus,
.btn-primary:hover {
  background: #04e4cc;
  border-color: #04e4cc;
}
.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #04e4cc;
  border-color: #04e4cc;
}
.dropdown-toggle {
  display: 'none';
}
.table {
  font-size: 18px;
}
.table tr {
  background-color: #f8f8f8;
}
.btn-secondary.remove,
.btn-secondary.remove:active,
.btn-secondary.remove:focus,
.btn-secondary.remove:hover {
  background-color: #999;
  border-color: #999;
}
.btn-secondary.remove {
  margin-top: 10px;
}
.no-scroll {
  overflow: hidden;
}
.select {
  cursor: pointer;
  display: inline-block;
  position: relative;
  font-size: 16px;
  color: #3e3e3e;
  width: 200px;
  height: 40px;
}
.lang-au span.price {
  display: flex !important;
  flex-direction: row-reverse;
}
.test:hover {
  box-shadow: 0 0 0 2px #00d6bc;
  border-radius: 5px;
}
.required:after {
  content: '*';
}
.custom-select {
  text-transform: capitalize;
}
.MuiTypography-body1 {
  font-size: 14px !important;
}
@media (min-width: 1400px) {
  .container {
    max-width: 1273px;
  }
}
@media (min-width: 1200px) {
  #wrapper {
    padding-top: 111px;
  }
}
@media (min-width: 768px) {
  .navbar-nav .nav-item:last-child {
    margin: 0;
  }
}
@media (max-width: 1599px) {
  .navbar-nav {
    font-size: 14px;
  }
  .navbar-nav .nav-item {
    margin-right: 22px;
  }
}
@media (max-width: 1199px) {
  .table {
    font-size: 15px;
  }
  .show .search-form {
    opacity: 1;
    visibility: visible;
  }
  .navbar-nav {
    margin: 0 0 10px;
  }
  .navbar-light .navbar-nav .active > .nav-link,
  .navbar-light .navbar-nav .focus > .nav-link,
  .navbar-light .navbar-nav .nav-link:focus,
  .navbar-light .navbar-nav .nav-link:hover,
  .navbar-light .navbar-nav .show > .nav-link,
  .navbar-nav .nav-link:hover {
    background: #000;
  }
  .navbar-light .navbar-nav .nav-link {
    display: block;
    padding: 10px;
  }
  .navbar-collapse {
    top: 100%;
    left: 0;
    width: 100%;
    z-index: 99;
    position: absolute;
    background: #fff;
  }
}
@media (max-width: 991px) {
  .select {
    font-size: 15px;
  }
  .searchcar .deals-left img {
    margin: 0 auto 10px;
  }
  .searchcar .deals-left {
    text-align: center;
  }
}
@media (max-width: 767px) {
  .wrapper-with-download-banner {
    padding-top: 200px;
  }
  .sticky-header .wrapper-with-download-banner {
    padding-top: 180px;
  }
  .lang-au .frame .qar {
    display: flex !important;
    flex-direction: row-reverse;
    justify-content: flex-end;
  }
  .slider-holder {
    width: 100%;
    margin-bottom: 20px;
  }
  .container {
    max-width: 100%;
  }
  .navbar-nav .nav-item {
    margin: 0;
  }
  .navbar-nav .nav-link {
    display: block;
    padding: 15px 0;
  }
  .lang-au .react-tel-input .form-control {
    width: 100%;
    text-align: right;
    padding-right: 50px;
  }
}
@media (max-width: 575px) {
  .slide-content .slider-img {
    width: 100% !important;
  }
}
@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}
@keyframes prixClipFix {
  0% {
    clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0);
  }
  25% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0);
  }
  50% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 100%);
  }
  75% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%);
  }
  100% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 0);
  }
}
/* to be moved back to CarouselBanner-styles.css */
.carousel-indicators li {
  padding: 0 8px;
  -webkit-box-flex: 0;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto !;
  width: auto;
  height: auto;
  margin: 0;
  text-indent: inherit;
  cursor: pointer;
  background-color: transparent;
  border-top: 0;
  border-bottom: 0;
  opacity: 1;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-transition: opacity 0.6s;
  transition: opacity 0.6s;
}
/* to be moved back to TabPages-styles.css */
.nav-tabs {
  border: none;
  padding: 5px 0 0;
}
.nav-tabs .nav-item {
  margin: 0 29px 0 0;
}
.nav-tabs .nav-item:last-child {
  margin: 0;
}
.nav-tabs .nav-link {
  border: none;
  border-radius: 0;
  padding: 0;
  font-size: 18px;
  line-height: 22px;
  color: #606161;
  font-weight: 400;
}
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #00d6bc;
  font-weight: 700;
}
.nav-link {
  cursor: pointer;
}
@media (max-width: 991px) {
  .nav-tabs .nav-link {
    font-size: 14px;
  }
  .nav-tabs .nav-item {
    margin-right: 20px;
  }
}
@media (max-width: 767px) {
  .nav-tabs .nav-item {
    margin: 0 15px 10px 0;
  }
}
/* to be moved back to SellYourCar-styles.css */
.sell-your-car {
  padding: 30px 7% 20px;
}
