#footer {
  overflow: hidden;
  background: #3d3d3d;
  padding: 40px 0 17px;
  margin-top: auto;
}
#footer h3 {
  text-decoration: underline;
  text-transform: uppercase;
  line-height: 15px;
  font-size: 12px;
  margin: 0 0 22px;
  font-weight: 700;
  color: #fff;
}
#footer .footer-logo,
#footer .footer-logo a {
  display: block;
}
#footer .btn-shop {
  width: 140px;
  height: 33px;
  border-radius: 3px;
  background: #ebebeb;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
#footer .btn-shop:hover {
  opacity: 0.7;
}
#footer .btn-shop .ico {
  display: block;
  margin: 0 10px 0 0;
}
#footer .footer-logo a img {
  display: block;
  height: auto;
  max-width: 100%;
}
#footer .btn-shop .txt {
  display: block;
  font-size: 16px;
  line-height: 18px;
  color: #848484;
  font-weight: 700;
  text-transform: uppercase;
}
#footer .footer-holder {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 73px;
}
.footer-button-as-link {
  text-decoration: none;
  outline: none;
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  text-align: left;
  color: white;
  white-space: nowrap;
}
.footer-button-as-link:focus {
  outline: none;
}
.footer-button-as-link:hover {
  color: #04e4cc;
}
.footer-links {
  margin: 0;
  padding: 0;
  font-weight: 400;
}
.footer-links li {
  margin: 0 0 3px;
}
.bottom-links li a:hover,
.footer-links li a:hover {
  color: #04e4cc;
}
.bottom-links,
.footer-links {
  line-height: 15px;
  font-size: 12px;
}
.social-icons {
  margin: 0;
  padding: 0;
  display: flex;
}
.social-icons li {
  margin: 0 0 0 18px;
}
.social-icons li:first-child {
  margin-left: 0;
}
.social-icons li a {
  display: flex;
  width: 30px;
  height: 30px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 100%;
  background: #00d6bc;
}
.social-icons li a:hover {
  background: #04e4cc;
}
.social-icons li a img {
  display: inline-block;
  height: auto;
  max-width: 100%;
}
.social-icons li a .fa {
  font-size: 15px;
  color: #fff;
}
.bottom-links,
.social-icons,
.social-icons li a {
  display: -webkit-box;
  display: -ms-flexbox;
}
.lang-au .social-icons li:first-child {
  margin-left: 18px;
}
.lang-au .social-icons li:last-child {
  margin-left: 0;
}
.bottom-links li a,
.footer-links li a {
  color: #fff;
}
.bottom-links,
.btn-list,
.footer-links {
  list-style: none;
}
.bottom-links li:first-child,
.footer-links li:last-child {
  margin: 0;
}
.bottom-links {
  margin: 0;
  padding: 0 26px 0 0;
  font-weight: 400;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}
.bottom-links li {
  margin: 0 0 0 20px;
  position: relative;
}
.bottom-links li + li:after {
  top: 3px;
  left: -11px;
  width: 2px;
  height: 12px;
  content: '';
  background: #fff;
  position: absolute;
}
.lang-au footer#footer ul.bottom-links {
  float: left;
  padding: 0 0 0 26px;
}
.lang-au .bottom-links li {
  margin-left: 0;
  margin-right: 20px;
}
.lang-au .bottom-links li + li:after {
  right: -11px;
  left: auto;
}
.btn-list li {
  padding: 0 8px;
}
.btn-list li a:hover {
  opacity: 0.7;
}
.btn-list {
  margin: 0 -8px 33px;
  padding: 0;
  display: flex;
}
.footer-overview-headings {
  text-transform: uppercase;
  white-space: nowrap;
}
.button-as-link {
  border: none !important;
  background: none !important;
  outline: none !important;
}
@media (max-width: 1199px) {
  .btn-list {
    justify-content: center;
  }
  #footer .btn-shop {
    margin: -5px 0 0 20px;
    position: static;
    float: right;
  }
  .social-icons {
    justify-content: center;
  }
  .bottom-links {
    padding: 0;
    justify-content: center;
  }
}
@media (max-width: 991px) {
  .btn-list {
    justify-content: center;
  }
  .bottom-links,
  .social-icons {
    justify-content: center;
    float: unset !important;
  }
}
@media (max-width: 767px) {
  .btn-list {
    justify-content: center;
  }
  #footer {
    overflow: hidden;
    background: #3d3d3d;
    padding: 40px 0 17px;
    margin-top: auto;
    text-align: center;
  }
  .lang-au footer#footer ul.bottom-links {
    float: unset !important;
    padding: 0;
  }
  .bottom-links,
  .social-icons {
    justify-content: center;
  }
  .lang-au .social-icons {
    float: unset !important;
  }
}
@media (max-width: 595px) {
  .lang-au #footer h3 {
    text-align: center;
  }
  .lang-au .footer-links {
    text-align: center;
  }
  .bottom-links,
  .lang-au .social-icons,
  .social-icons {
    justify-content: center;
  }
  .bottom-links {
    padding-left: 15px;
  }
  .lang-au .bottom-links {
    justify-content: center;
    padding-right: 15px;
  }
}
@media (max-width: 575px) {
  #footer .btn-shop {
    width: 85px;
    margin: 0 5px;
  }
  #footer .btn-shop .ico {
    margin: 0 5px 0 0;
  }
  #footer .btn-shop .txt {
    font-size: 14px;
  }
}
@media (max-width: 479px) {
  .lang-au .social-icons {
    justify-content: right;
  }
  .social-icons {
    justify-content: left;
  }
  .lang-au .bottom-links {
    justify-content: right;
    padding-right: 15px;
  }
  .bottom-links {
    justify-content: left;
    padding-left: 15px;
  }
}
