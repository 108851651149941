.login-holder {
  max-width: 1060px;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  position: relative;
  padding: 35px 0;
  margin: 0 auto;
}
.errorMessage {
  color: #e7505a;
  padding: 5px 0;
}
.login-info {
  width: 36%;
  background-color: #fff;
  border-radius: 0 10px 10px 0;
  border: 1px solid #e7e7e7;
  padding: 3% 5% 4%;
  text-align: center;
  overflow: hidden;
  min-height: 660px;
}
.login-form {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  font-size: 17px;
  position: relative;
  color: #919191;
  height: 100%;
  padding-bottom: 70px;
  box-sizing: border-box;
}
.login-form h2 {
  font-size: 25px;
  line-height: 1.5;
  color: #3d3d3d;
  margin: 0 0 15px;
  font-weight: 700;
}
.login-form .forget-password {
  color: #cbcbcb;
  text-decoration: underline;
  font-size: 15px;
}
.login-form .or {
  display: none;
  margin-bottom: 30px;
}
.login-form .forget-password:hover {
  text-decoration: none;
  color: #00d6bc;
}
.login-form .qr-code {
  padding-block: 20px;
  position: relative;
}
.login-form .qr-code::before {
  position: absolute;
  content: '';
  left: -9999px;
  right: -9999px;
  top: 0;
  border-top: 3px solid #e3e3e3;
}
.login-form .btn-primary {
  border-radius: 10px;
  font-size: 22px;
  line-height: 1.4;
  padding: 5px 15px 7px;
  margin: 0 auto;
  display: block;
  text-transform: capitalize;
  width: 100%;
}
.login-form .btn-primary:active,
.login-form .btn-primary:focus,
.login-form .btn-primary:hover,
.login-form .btn-primary:not(:disabled):not(.disabled).active,
.login-form .btn-primary:not(:disabled):not(.disabled):active,
.login-form .show > .btn-primary.dropdown-toggle {
  background-color: #cbcbcb;
  border-color: #cbcbcb;
  color: #54585a;
  box-shadow: none;
}
.login-form .need-account .btn-primary {
  background-color: #cbcbcb;
  border-color: #cbcbcb;
  color: #54585a;
}
.login-form .need-account .btn-primary:active,
.login-form .need-account .btn-primary:focus,
.login-form .need-account .btn-primary:hover,
.login-form .need-account .btn-primary:not(:disabled):not(.disabled).active,
.login-form .need-account .btn-primary:not(:disabled):not(.disabled):active,
.login-form .need-account .show > .btn-primary.dropdown-toggle {
  background-color: #00d6bc;
  border-color: #00d6bc;
  color: #fff;
}
.need-account {
  padding-top: 25px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -20px;
}
.need-account strong {
  display: block;
  margin-bottom: 15px;
  font-weight: 400;
}
.login-logo,
.login-tabs {
  margin-bottom: 15px;
}
.login-logo img {
  max-width: 105px;
}
.login-tab {
  font-family: Lato;
  font-weight: 700;
}
.need-account:before {
  position: absolute;
  content: '';
  left: -9999px;
  right: -9999px;
  top: 0;
  border-top: 3px solid #e3e3e3;
}
.forgot-password-secondary-button {
  background-color: '#ffff';
  border: none;
  min-width: ' 160px';
  text-align: 'center';
  color: '#00d6bc';
  outline: 0;
  font-weight: 'bold';
  text-transform: 'uppercase';
  letter-spacing: '1px';
}
.otp-input {
  border: 1px solid #d1d1d1;
}
.otp-input:focus {
  outline: 0;
  border: 1px solid #00d6bc;
}
@media (max-width: 1199px) {
  .login-info {
    padding: 5% 3.5%;
  }
}
@media (max-width: 991px) {
  .login-holder {
    padding: 35px 0;
  }
  .login-info {
    width: 100%;
    border-radius: 10px;
    padding: 8%;
    min-height: unset;
  }
  .login-form .btn-primary {
    font-size: 18px;
  }
  .login-form {
    padding: 25 0;
    height: fit-content;
  }
  .login-logo,
  .login-tabs {
    margin-bottom: 20px;
  }
  .need-account {
    position: relative;
  }
}
