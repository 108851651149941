.form-title {
  color: #fff;
  position: relative;
  z-index: 9;
}
.form-title.search:after {
  position: absolute;
  content: '';
  left: 10px;
  bottom: -74px;
  width: 208px;
  height: 236px;
  background: url(/public/images/form-bg.png) 0 0/208px 236px no-repeat;
  display: block;
}
.form-title:before {
  position: absolute;
  content: '';
  display: block;
  z-index: -1;
  bottom: -75px;
  right: 95px;
  top: -88px;
  left: 0;
  border-radius: 10px 0 0 10px;
  background-color: #464647;
}
.form-title h2 {
  font-size: 33px;
  font-weight: 900;
  margin: 0;
  padding: 0 0 0 10%;
  position: relative;
}
.form-title h2:before {
  position: absolute;
  content: '';
  z-index: -1;
  bottom: -57px;
  top: -88px;
  left: 100%;
  width: 0;
  height: 0;
  margin-left: -80px;
  border-style: solid;
  border-width: 229px 100px 0 0;
  border-color: #464647 transparent transparent;
}
.btn-reset {
  border: 0;
  padding: 0;
  background: 0 0;
  position: absolute;
  right: 10px;
  top: -20px;
  color: #fff;
  z-index: 99;
  font-size: 18px;
  outline: 0;
}
.lang-au .btn-reset {
  left: 10px;
  right: unset;
}
.btn-advance {
  text-decoration: underline;
}
.btn-advance {
  color: #fff;
  padding: 2px;
  border: 0;
  font-size: 12px;
  background: 0 0;
}
.btn-advance:active,
.btn-advance:focus,
.btn-advance:hover {
  color: #fff;
  text-decoration: none;
  background: 0 0;
  outline: 0;
  box-shadow: none;
}
.arabic-reset-filter {
  left: 10px;
  right: unset;
}
@media (min-width: 1200px) {
  .form-title.search h2 {
    padding-left: 225px;
    font-size: 28px;
  }
  .form-title.search h2 .txt {
    display: block;
  }
  .form-title.search h2 .for {
    font-size: 20px;
    vertical-align: top;
    margin-right: 8px;
  }
}
@media (max-width: 1399px) {
  .form-title.search h2 .for {
    font-size: 16px;
  }
  .form-title h2:before {
    bottom: -57px;
    top: -95px;
    margin-left: -72px;
  }
  .form-title:before {
    right: 87px;
    top: -95px;
    bottom: -81px;
  }
  .form-title.search:after {
    bottom: -81px;
  }
  .form-title.search h2 {
    font-size: 22px;
  }
  .form-title:after {
    bottom: -66px;
  }
}
@media (max-width: 1199px) {
  .btn-reset {
    top: -10px;
  }
  .form-title h2 {
    font-size: 26px;
    letter-spacing: 2px;
    padding-left: 0;
    font-weight: 700;
  }
  .form-title.search {
    padding-bottom: 15px;
    margin-bottom: 15px;
  }
  .form-title.search:after {
    display: none;
  }
  .form-title.search:before {
    bottom: 0;
    right: 0;
    top: -10px;
    left: 0;
    border-radius: 10px 10px 0px 0px;
    transform: skewX(0);
  }
  .form-title.search h2 {
    font-size: 22px;
    padding-left: 0;
  }
  .form-title.search h2 .for {
    font-size: inherit;
  }
  .form-title h2:before {
    display: none;
  }
}
@media (max-width: 991px) {
  .form-title {
    margin-bottom: 20px;
  }
  .form-title h2:before {
    display: none;
  }
  .form-title:before {
    bottom: 0;
    right: 0;
    top: -10px;
    left: 0;
    border-radius: 10px 10px 0px 0px;
  }
  .form-title {
    padding-bottom: 15px;
  }
  .form-title h2 {
    font-size: 20px;
    padding: 0;
  }
}
@media (max-width: 479px) {
  .form-title h2 {
    letter-spacing: 1px;
  }
}
