.listed-featured-details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 48px;
  flex: 1 0 0;
}
.listed-featured-details__header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
}
.listed-featured-details__header-title {
  align-self: stretch;
  color: #000;
  font-family: Lato;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 17.156px;
}
.lang-au .listed-featured-details__header-title {
  text-align: right;
}
.listed-featured-details__header-description {
  align-self: stretch;
  color: #1c2628;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}
.listed-featured-details__deals-list {
  display: flex;
  padding: 8px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  border-radius: 5px;
  border: 1px solid #c5c5c5;
}
.listed-featured-details__deals-list-content {
  align-self: stretch;
  overflow-y: auto;
}
.listed-featured-details__deals-list-content::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}
.listed-featured-details__deals-list-content::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}
.listed-featured-details__deal {
  display: flex;
  padding: 16px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}
.listed-featured-details__deal-image {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
}
.listed-featured-details__deal-price {
  display: flex;
  width: 146px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  color: #000;
  font-family: Lato;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 17.156px;
}
@media screen and (max-width: 600px) {
  .deal-image {
    width: 130px !important;
  }
  .listed-featured-details__deal-price {
    font-size: 11px;
  }
  .listed-featured-details__deal-image {
    gap: 5px;
  }
}
