.accounts-info {
  border-top: 10px solid #ebe9e9;
  padding: 20px 5%;
  overflow: hidden;
}
.accounts-info:first-child {
  border-top: 0;
}
.modal-center-style {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh;
}
.account-details-phone-number {
  direction: ltr;
}
.accounts-info h2 {
  color: #00d6bc;
  font-size: 20px;
  line-height: 1.4;
  text-transform: capitalize;
  margin-bottom: 12px;
  font-weight: 700;
}
.accounts-info .cars-info-list {
  font-size: 17px;
}
.accounts-info .cars-info-list dt {
  min-width: 200px;
  max-width: 200px;
}
.accounts-info .cars-info-list dd {
  margin-bottom: 7px;
}
.accounts-info .cars-info-list dd .form-control {
  border: 0;
  background-color: transparent;
  height: 30px;
  padding: 0;
  display: none;
  color: #000;
  font-weight: 700;
  font-size: 17px;
}
.accounts-info .cars-info-list dd .form-control::-webkit-input-placeholder {
  color: #000;
  font-weight: 700;
}
.accounts-info .cars-info-list dd .form-control:-ms-input-placeholder {
  color: #000;
  font-weight: 700;
}
.accounts-info .cars-info-list dd .form-control::placeholder {
  color: #000;
  font-weight: 700;
}
.accounts-info .cars-info-list dd .form-control:focus {
  box-shadow: none;
  font-weight: 700;
}
.editing {
  border: 1px solid #d1d1d1 !important;
  border-radius: 5px !important;
  width: fit-content !important;
  background-color: #f6f6f6 !important;
}
.modal-action-buttons {
  gap: 48px;
}
.cars-info-list {
  font-size: 15px;
  line-height: 1.5;
}
.cars-info-list dt {
  float: left;
  min-width: 90px;
  max-width: 90px;
  margin-right: 10px;
  clear: both;
}
.cars-info-list dd {
  overflow: hidden;
  font-weight: 700;
}
.close-modal.advance-search-modal .btn-primary.cancel {
  background-color: #999;
  border-color: #999;
}
.close-modal.advance-search-modal .modal-footer {
  margin-top: 0;
  flex-direction: column;
}
.close-modal.add-new-modal .modal-body,
.close-modal.advance-search-modal .modal-body {
  text-align: center;
  margin-bottom: 30px;
  font-size: 30px;
  line-height: 1.4;
}
.close-modal.advance-search-modal button[type='reset'] {
  margin-right: 0;
}
.close-modal.add-new-modal .modal-content,
.close-modal.advance-search-modal .modal-content {
  padding: 5%;
}
.close-modal.add-new-modal .modal-dialog,
.close-modal.advance-search-modal .modal-dialog {
  max-width: 700px;
}
.close-modal.add-new-modal .btn-primary,
.close-modal.advance-search-modal .btn-primary {
  min-width: 120px;
  border-radius: 15px;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 1px;
  margin-bottom: 10px;
}
.close-modal.advance-search-modal .btn-primary.cancel:active,
.close-modal.advance-search-modal .btn-primary.cancel:focus,
.close-modal.advance-search-modal .btn-primary.cancel:hover {
  background-color: #464647;
  border-color: #464647;
}
.close-modal.add-new-modal .modal-body span {
  font-size: 18px;
  display: block;
  padding-top: 15px;
}
.close-modal.add-new-modal .modal-body p {
  line-height: 1.4;
}
.close-modal .generic-form {
  padding-top: 30px;
}
.close-modal.add-new-modal .modal-footer.text-right {
  flex-direction: row;
  margin-top: -30px;
}
.close-modal .generic-form label {
  text-align: left;
}
.close-modal.add-new-modal .modal-footer {
  margin-top: 0;
  flex-direction: column;
  border-top: 0;
  padding: 0;
}
.close-modal.add-new-modal .btn-primary.cancel {
  background-color: #999 !important;
  border-color: #999 !important;
}
.close-modal.add-new-modal .modalimg {
  max-width: 76px;
  margin: 0 auto 15px;
}
.close-modal.add-new-modal .modalimg img {
  max-height: 76px;
}
.edit-deals {
  position: absolute;
  right: 10px;
  top: 10px;
  font-size: 24px;
  color: #a4a4a4;
}
.edit-deals:focus,
.edit-deals:hover {
  color: #00d6bc;
}
.loginpopup {
  background-color: rgba(255, 255, 255, 0.8);
}
@media screen and (max-width: 767px) {
  .accounts-info .cars-info-list {
    font-size: 17px;
    display: grid;
  }
}
@media screen and (min-height: 400px) {
  .modal-open .modal.modal-center {
    display: flex !important;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex !important;
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center !important;
  }
  .modal-open .modal.modal-center .modal-dialog {
    flex-grow: 1;
  }
}
.lang-au .accounts-info {
  text-align: right;
}
