.steps-wizard-modal .steps-wizard-modal-dialog {
  max-width: 100%;
  height: 100%;
  min-height: 100%;
  padding: 0;
  margin: 0;
  pointer-events: auto;
}
.steps-wizard-modal .steps-wizard-modal-content {
  max-width: 750px;
  padding-inline: 15px;
  margin: 2rem auto;
  border: 0;
  position: static;
  background-color: transparent;
}
.steps-title {
  color: #1bd0ae;
  font-size: 98px;
  line-height: 1;
  font-weight: 900;
  margin-bottom: 25px;
}
.steps-wizard-form label {
  font-size: 35px;
  font-weight: normal;
  margin-bottom: 25px;
}
.steps-wizard-form label b {
  font-weight: 700;
}
.steps-wizard-modal.modal {
  background-color: #fff;
}
.step-app > .step-steps {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  border-radius: 3px 3px 0 0;
  overflow: hidden;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 5%;
  max-width: 500px;
  margin: auto;
}
.step-app > .step-steps > li {
  list-style: none;
  cursor: pointer;
  display: block;
  padding: 0;
  color: #fff;
  background-color: #c1c1c1;
  text-decoration: none;
  width: 32px;
  height: 32px;
  border-radius: 100%;
  text-align: center;
  line-height: 31px;
  font-size: 22px;
  margin-inline: 15px;
  transition: all 0.3s ease;
  position: relative;
  pointer-events: none;
}
.step-app > .step-steps > li::before {
  height: 4px;
  width: 42px;
  background-color: #c1c1c1;
  position: absolute;
  left: 20px;
  top: 50%;
  transform: translateY(-50%);
  content: '';
  z-index: -1;
}
.step-app > .step-steps > li:last-child:before {
  display: none;
}
.step-app > .step-steps > li:hover {
  background-color: #1bd0ae;
}
.step-app > .step-steps > li:last-child a {
  border: none;
}
.step-app > .step-steps > li.active {
  background-color: #1bd0ae;
  color: #fff;
  pointer-events: auto;
}

.step-app > .step-steps > li.error {
  background-color: #e7505a;
  color: #fff;
}
.step-app > .step-steps > li.done {
  background-color: #1bd0ae;
  color: #fff;
  pointer-events: auto;
}
.step-app > .step-steps > li.done::before {
  background-color: #1bd0ae;
}
.step-app > .step-steps > li > .number {
  background: #fff;
  padding: 0 8px;
  display: inline-block;
  text-align: center;
  margin-right: 15px;
  border-radius: 3px;
  color: #333;
}
.step-app > .step-content > .step-tab-panel {
  display: none;
}
.step-app > .step-content > .step-tab-panel.active {
  display: block;
}
.step-app > .step-footer {
  margin-top: 15px;
  margin-bottom: 15px;
}
.step-app > .step-footer > .step-btn {
  padding: 4px 16px;
  color: #333;
  text-decoration: none;
  background: #e5e5e5;
  border-radius: 3px;
  border: none;
  outline: none;
  cursor: pointer;
}
.close-steps-wizard-modal {
  position: absolute;
  right: 15px;
  top: 5px;
  font-size: 50px;
  font-weight: 400;
  opacity: 1;
  color: #3d3d3d;
  outline: none;
  transition: all 0.3s ease;
}
.close-steps-wizard-modal:focus,
.close-steps-wizard-modal:active,
.close-steps-wizard-modal:hover {
  color: #04e4cc;
  outline: none;
}
.steps-wizard-form .form-control {
  background-color: #fff;
  border: 0;
  border-bottom: 3px solid #1bd0ae;
  font-size: 29px;
  padding-left: 0;
  height: 50px;
  color: #bdbdbd;
}
.steps-wizard-form .form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #1bd0ae;
  outline: 0;
  box-shadow: none;
}
.steps-wizard-form .form-group {
  margin-bottom: 3rem;
}
.steps-wizard-form .btn-primary {
  font-size: 18px;
}
.steps-wizard-modal.modal-completed.modal {
  background-color: #00d6bc;
}
.vertical-center {
  display: flex;
  align-items: center;
  min-height: 100vh;
}
.steps-wizard-modal-completed .steps-wizard-form p {
  margin-bottom: 40px;
  font-size: 34px;
  font-weight: 400;
}
.steps-wizard-modal-completed .steps-wizard-form .btn-primary,
.thank-you-page .btn-primary {
  font-size: 18px;
  padding: 10px 15px;
  height: auto;
  border-color: #fff;
  margin-right: 50px;
  border-radius: 5px;
  font-weight: 600;
}
.steps-wizard-modal-completed .steps-wizard-form .btn-primary:focus,
.steps-wizard-modal-completed .steps-wizard-form .btn-primary:active,
.steps-wizard-modal-completed .steps-wizard-form .btn-primary:hover {
  background-color: #fff;
  color: #3d3d3d;
}
.steps-wizard-modal-completed .steps-wizard-form .btn-primary.back {
  background-color: #fff;
  color: #3d3d3d;
}
.steps-wizard-modal-completed .steps-wizard-form .btn-primary.back:focus,
.steps-wizard-modal-completed .steps-wizard-form .btn-primary.back:active,
.steps-wizard-modal-completed .steps-wizard-form .btn-primary.back:hover {
  background-color: transparent;
}
.steps-wizard-modal-completed .close-modal:focus,
.steps-wizard-modal-completed .close-modal:active,
.steps-wizard-modal-completed .close-modal:hover {
  color: #3d3d3d;
}
